/* Define the pressed animation for a button */
.pressed-animation {
    transition: transform 0.2s ease-in-out;  
    
    &:active {
      transform: scale(0.90);
    }
    
    &:not(:active) {
      transform: scale(1);
    }
  }