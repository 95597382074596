.DarkTheme .alert-bg-color {
  background-color: #292e39;
}

 .alert-bg-color {
  background-color: #f6f8fc;
}

.DarkTheme .hamburger-menu{
  color: white;
}
.hamburger-menu{
  color: #171D27;
}

.DarkTheme .alert-heading{
  color: #E4EAF0;
}

.DarkTheme .alert-description{
  color: #8392A0;
}

.alert-heading{
  color: #171D27;
}

.alert-description{
  color: #8392A0;
}


//  common styles
.br-12{
    border-radius: 12px;
}

.font-14{
    font-size: 14px;
    line-height: 18px;
}

.font-weight-700{
    font-weight: 700;
}

/* SCSS for Delete Alert Icon */
.delete-alert {
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: #4A4A4A !important; 
  transition: color 0.3s ease;

  .DarkTheme & {
    color: white!important;
  }

  &:hover {
    color: red !important;
  }
}


/* SCSS for Delete Alert Icon */
.edit-alert {
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: #4A4A4A !important;
  transition: color 0.3s ease; 

  .DarkTheme & {
    color: white!important;  
  }

  &:hover {
    color: green !important; 
  }
}