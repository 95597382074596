@import "../../../../scss/colors";

.DarkTheme .gtt-tabs-border-white {
  border-bottom: 1px solid #8392a0 !important;
}

.gtt-tabs-border-white {
  border-bottom: 1px solid #e4eaf0 !important;
}

.DarkTheme .gtt-selected-border-bottom {
  border-bottom: 2px solid $candle-white !important;
}

.gtt-selected-border-bottom {
  border-bottom: 2px solid $candle-black !important;
}


// Default state
.gtt-gray-hover {
    background-color: transparent;
    color: #000000; 
    transition: background-color 0.3s ease, color 0.3s ease;  // Add color transition
}

.DarkTheme .gtt-gray-hover {
    color: $candle-white; 
}

// Dark theme hover state
.DarkTheme .gtt-gray-hover:hover {
    background-color: #F1F1F1 !important; 
    color: $candle-black; 
}

// Default hover state
.gtt-gray-hover:hover {
    background-color: #F1F1F1 !important;  
}

.font-700{
    font-weight: 700;
}

.font-500{
    font-weight: 500;
}