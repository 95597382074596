@import "../../../scss/colors";

.overflow-auto::-webkit-scrollbar {
  width: 8px;
}

.overflow-auto::-webkit-scrollbar-track {
  background: var(--bs-light);
}

.DarkTheme .overflow-auto::-webkit-scrollbar-track {
  background: var(--bs-secondary);
}

.overflow-auto::-webkit-scrollbar-thumb {
  background: var(--bs-gray);
  border-radius: 4px;
}

.DarkTheme .overflow-auto::-webkit-scrollbar-thumb {
  background: var(--bs-white);
}

.overflow-auto::-webkit-scrollbar-thumb:hover {
  background: var(--bs-light);
}

.DarkTheme .overflow-auto::-webkit-scrollbar-thumb:hover {
  background: var(--bs-white);
}
