.DarkTheme .create-or-edit {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: white;
}

.create-or-edit {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}