.css_input_box {
    border-width: 20px;
    border-color: #F6F8FC;
    background-color: #8392A0;
    color: #181717;
    border-style: solid;
    border-radius: 50px;
    width: 4rem !important;
    height: 4rem;
    margin: 5px;
    font-size: 10px;
    text-align: center;
}
/*.css_input_box:focus {*/
/*    outline: 1px solid blue;*/
/*}*/

.flow{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #171D27;
}

.circle {
    border-radius: 50%;
    width:70px;
    height:70px;
    padding: 23px;
    background: #009EFF;
    color:white;
    text-align: center;
    vertical-align:middle;
    font: 20px Arial, sans-serif;
}

.card_padding{
    padding: 35px;
}

@media screen and (max-width: 768px){
    .card_padding{
        padding: 22px;
    }
}

