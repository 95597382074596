.cardBox{
    padding: 12px 16px;
    background: #FFFFFF;
    border: 1px solid #E4EAF0;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 15px;
}

.icon_grey{
    color:  #8392A0;
}
