.each_box{
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
    background: #F6F8FC;
    border-radius: 8px;
}

.type_badge{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px;
    background: #E4EAF0;
    border-radius: 4px;
    color: #171D27;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
}


.buysell_badeg{
    box-sizing: border-box;
    border-radius: 11px;
    color: white;
    width: 15px;
    height: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
}

.color_buy{
    background: #28C269;
    border: 2px solid #28C269;
}

.color_sell{
    background: #FE4141;
    border: 2px solid #FE4141;
}


.text_head1{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 0.7rem;
    /*font-size: 12px;*/
}

.text_thin1{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #586572;
}

.authorise_box{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 8px;
    border: 1px solid #064AD3;
    border-radius: 6px;
    color: #064AD3;
    font-size: 11px;
}

.grab { cursor: grab;}
.scroll_box2{
    height: 290px;
    overflow-y: auto;
}

.drover_box{
    display: none;
    background: #F6F8FC;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
}

.menuBox:hover .drover_box{
    display: block;
}


.tooltipMenu {
    display:inline-block;
    position:relative;
    text-align:center;
    cursor: pointer;
}

.tooltipMenu .top {
    top:-10px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:5px 7px;
    color:#FFFFFF;
    background-color:#222222;
    font-weight:800;
    font-size:10px;
    border-radius:8px;
    position:absolute;
    z-index:100;
    box-sizing:border-box;
    /*box-shadow:0 1px 3px rgba(0,0,0,0.5);*/
    display:none;
}

.tooltipMenu:hover .top {
    display:block;
}

.tooltipMenu .top i {
    position:absolute;
    top:100%;
    left:50%;
    margin-left:-10px;
    width:20px;
    height:10px;
    overflow:hidden;
}

.tooltipMenu .top i::after {
    content:'';
    position:absolute;
    width:10px;
    height:10px;
    left:50%;
    transform:translate(-50%,-50%) rotate(45deg);
    background-color:#222222;
    /*box-shadow:0 1px 8px rgba(0,0,0,0.5);*/
}

.back_side{
    display: flex;
    width: 100px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 800;
}
.Canceled{
    background: #FFEEDC;
    color: #FF992A;
}

.Working{
    background-color: #a4a4f8;
    color: blue;
}

.Filled{
    background-color: #DFF2EB;
    color: #29A673;
}

.Inactive{
    background-color: #e8e8e8;
    color: #585757;
}

.Rejected{
    background-color: #FFEDED;
    color: #FE4141;
}

.excecute_btn{
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    background: #F6F8FC;
    border: 1px solid #064AD3;
    border-radius: 4px;
    color: #064AD3;
}

.DarkTheme .type_badge{
    background: #4b5464;
    color: #ffffff;
}

.DarkTheme .text_head1{
    color: #D0D9E2;
}
.DarkTheme .drover_box{
    background: #292E39;
}


@media screen and ( min-height: 900px ) {

    .scroll_box2{
        height: 372px;
        overflow-y: auto;
    }

}
