.charges-container{
    margin: auto;
    position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
    width: 25%;
    background-color: white;
    border: 1px solid #E4EAF0;
    height: 50%;
    z-index: 500;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    // border-radius: 5px;
    border-radius: 12px;
    padding-bottom: 10px;

}

.title{
    display: flex;
	padding: 16px 20px 16px 10px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
	flex-direction: row;
	background: #F6F8FC;
	height: 82px;
	justify-content: space-between;
    align-items: center;
    cursor: move;
    font-style: normal;
    font-weight: 700px;
    font-size: 18px;
    color: #171D27;
}

.subtitle{
    padding-left: 10px;
    font-style: normal;
    font-weight: 700px;
    font-size: 18px;
    color: #171D27;
}

.charges-row{
    padding: 0 10px 0 10px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.charges-value{
    font-weight: 700;
}
.bold{
    font-weight: 700;
}