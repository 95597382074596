.my_badge_topic{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    background: #FFFFFF;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    margin: 0 4px;
    cursor: pointer;
}

.active{
    background: #064AD3;
    color: white;
}

.NameLable{
    width: 48px;
    height: 48px;
    background: #DDF0FF;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.text_dark{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #171D27;
}

#badge_exch {
    margin-left: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    background: #E4EAF0;
    border-radius: 2px;
    font-family: "HK Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #171D27;
    height: 15px;
    margin-top: 3px;
}

.text_low{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #8392A0;
}

.srollbox{
    overflow-y: auto;
    height: 250px;
}



table.bottomBorder {
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
}
table.bottomBorder td,
table.bottomBorder th {
    border-bottom: 1px solid #c6c3c3;
    padding: 5px;
    text-align: left;
}

table.bottomBorder tbody tr:hover {
    background-color: #d9d9d5;
    cursor: pointer;
}

 table.bottomBorder > tbody > tr > td:nth-child(3){
     text-align: right;
     padding-right: 10px;
 }


.bottomBorder          { overflow-y: auto; height: 100px; }
.bottomBorder thead th { position: sticky; top: 0; z-index: 2; }


.table_bg{
    width: 99%;
    height: 30px;
    background-color: #ffffff;
    z-index: 1;
    position: absolute;
    top: 0;
}

.datarow:hover{
    background-color: rgba(246, 246, 246, 0.8);
    cursor: pointer;
}

.DarkTheme .datarow:hover{
    background-color: #050505;
    cursor: pointer;
}


.bottom_execute_bar{
    position: absolute;
    bottom: -2px;
    height: 65px;
    width: 100%;
    background-color: #E4EAF0;
    z-index: 9;
    border-radius: 0 0 12px 12px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    /*border-bottom: 1px solid white;*/
    margin-bottom: 2px;
}


table.baskTable {
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
    vertical-align: middle;
    border-collapse: separate;
    border-spacing: 0 8px;
}
table.baskTable td, table.baskTable th {
    padding: 6px 5px;
    vertical-align: middle;
}
table.baskTable thead {
    background: #F6F8FC;
}
table.baskTable thead th {
    font-weight: normal;
}


table.baskTable td:first-child,th:first-child {
    border-left-style: solid;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
table.baskTable td:last-child,th:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

table.baskTable thead th {
    font-family: 'HK Grotesk', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #171D27;
}

.priceTag{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    color: #586572;
}

table.baskTable > tbody > tr > td:nth-child(1){
    width: 40px;
}
table.baskTable > tbody > tr > td:nth-child(3){
    width: 12%;
    /*background-color: blue;*/
}
/*table.baskTable > tbody > tr > td:nth-child(3){*/
/*    text-align: right;*/
/*}*/
/*table.baskTable > tbody > tr > td:nth-child(4){*/
/*    text-align: right;*/
/*}*/
/*table.baskTable > tbody > tr > td:nth-child(5){*/
/*    text-align: right;*/
/*}*/

/*table.baskTable > thead > tr > th:nth-child(3){*/
/*    text-align: center;*/
/*}*/
/*table.baskTable > thead > tr > th:nth-child(4){*/
/*    text-align: center;*/
/*}*/
/*table.baskTable > thead > tr > th:nth-child(5){*/
/*    text-align: center;*/
/*}*/

/*DARK theme*/

.DarkTheme table.baskTable thead th {
    color: #b2b5be;
}
.DarkTheme .priceTag{
    color: #b2b5be;
}




