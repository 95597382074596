.optionStrategyModel{
    position: absolute;
    top: 10%;
    left: calc(8% + 230px);
    box-sizing: border-box;
    padding: 0px 0px;
    border: 0px solid #143a8799;
    width: 900px;
    height: 518px;
    background: #FFFFFF;
    box-shadow: 0px 8px 33px 8px #18274B17
;
    border-radius: 12px;
    z-index: 41;
}
.DarkTheme .optionStrategyModel{
    background-color: #171D27;
    border: 1px solid #434651;
    box-shadow: 8px 8px 4px rgba(82, 82, 82, 0.1);
}

.DarkTheme .optionStartegyHead{
    background-color: #171D27;
    color: #B2B5BE;
    border-bottom: 1px solid #434651;
}

.arr{
    position: absolute;
    right: 15px;
    top: 15px;
}

.DarkTheme .arr{
    color: #B2B5BE;
}

.optionStartegyHead{
    display: flex;
    width: 100%;
    padding: 16px 20px 16px 20px;
    flex-direction: row;
    background: #F6F8FC;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px 12px 0px 0px;
    cursor: move;
}

.DarkTheme .custom-placeholder::placeholder {
    /* Placeholder styles */
    color: #B2B5BE;
  }

.headerText{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #171D27;
}
.DarkTheme .headerText{
    color: #B2B5BE;
}
.relative{
    position: relative;
}
.image{
    position: absolute;
    top: 50%;
    left:0px;
}
.textHead{
    position: absolute;
    top: 7px;
    left:66px;
}
.description{
    position: absolute;
    top: 34px;
    left: 66px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #586572;
}

.DarkTheme .description{
    color: #B2B5BE;
}

.tools-back{
    padding: 12px 16px;
    background: #F6F8FC;
    border: 1px solid #E4EAF0;
    border-radius: 16px;
    height: 80px;
}

.DarkTheme .tools-back{
    background: #292E39;
border: 1px solid #434651;
}

.foot{
height: 136px;
background: #FFFFFF;
border: 1px solid #E4EAF0;
border-radius: 16px;
}

.DarkTheme .foot{
    background: #292E39;
    border: 1px solid #434651;
}

.foot-desc{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #586572;
}

.DarkTheme .foot-desc{
    color: #B2B5BE;
}

.knowLink{
    padding-top: 16px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #064AD3;
    }
.recentSearches{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #171D27;
    position: relative;
}

.DarkTheme .recentSearches{
    color: #B2B5BE;
}
.exc{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #171D27;
    background: #E4EAF0;
    border-radius: 2px;
    padding: 2px 4px;
    position: absolute;
    top: 10px;
}
.desc{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8392A0;
}

.boundary{
height: 8px;    
background: #F6F8FC;
margin-top: 4%;
}

.DarkTheme .boundary{
   background-color:#26313D;
}

.DarkTheme .abc{
    background-color:  #292E39;
}

.button-container {
    display: flex;
    flex-direction: row;
    padding-left: 32px;
    overflow-x: scroll;
    align-items: center;
  }
  
  .expDateButton {
    margin: 5px;
    padding:5px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    background: #FFFFFF;
    cursor: pointer;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #171D27;
  }
 .DarkTheme .expDateButton{
    background: #323232;
    border: 1px solid #8392A0;
    color: #B2B5BE;
 }
  
  .button-container div:hover {
    background-color: #064AD3;
    font-weight: 600;
    color: #B2B5BE;
  }

  .expDateButton.active{
    background-color: #064AD3;
    font-weight: 600;
    color: #FFFFFF;
  }
  .DarkTheme   .expDateButton.active{
    background-color: #064AD3;
    font-weight: 600;
    color: #FFFFFF;
    border-color: #064AD3;
  }

  ::-webkit-scrollbar {
    width: 1px;
  }

  .strategy{
    background: #FFFFFF;
    border: 1px solid #E4EAF0;
    border-radius: 12px;
    height: 94px;
    width: 100%;
    box-shadow: 3px 5px #E4EAF0;
  }

  .DarkTheme .strategy{
    background: #292E39;
    border: 1px solid #434651;
    border-radius: 12px;
    box-shadow: 0px 0px #434651;
  }

  .builderHead{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #E4EAF0;
    padding-bottom: 17px;
  }
  .DarkTheme .builderHead{
    background: #292E39;
border: 1px solid #434651;
border-radius: 12px 12px 0px 0px;
  }

  .DarkTheme .predictionStart{
    background-color:#171D27;
    height: 97px;
    border-radius: 0px 0px 12px 12px;
  }

  .strategy-container{
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  
.flex-container{
    display: flex;
    flex-direction: row;
}

.cepe{
    display: flex;
    align-items: center;
    padding: 2px 4px;
    width: 22px;
    height: 16px;
    background: #E4EAF0;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 500;
    margin-left: 8px;
}
.ATMOTM{
    font-family: 'HK Grotesk';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #26313D;
width: 75px;
}

.DarkTheme .ATMOTM{
    color: #B2B5BE;
}

.border-right{
    border-right: 0.5px solid #D3D3D3;
    width: 124px;
}

.stltp{
    text-align: right;
    position: absolute;
    left: 23%;
    top: 32%;
    width: 150px;
}

.tradingOpportunies{
    width: 100%;
    padding: 20px;
    height: 82%;
    overflow-y: scroll;
}

.eachStrategy{
    // height: 206px;
    border: 1px solid #E4EAF0;
    border-radius: 12px;
    position: relative;
}
.DarkTheme .eachStrategy{
    border:1px solid #434651
}

.nameQuantity{
    background: #E4EAF0;
    border-radius: 12px 12px 0px 0px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items:center ;
}

.DarkTheme .nameQuantity{
    background: #292E39;
    border-bottom: 1px solid #434651;
    border-radius: 12px 12px 0px 0px;
}

.multiplier{
    position: absolute;
    right: 11px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.multText{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #171D27;
}

.addSubtractBox{
    padding: 2px 4px;
    width: 24px;
    height: 24px;
    background: #F6F8FC;
    border-radius: 2px 0px 0px 2px;
    font-size:15px;
    font-weight: 800;
}

.stratName{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #171D27;
}

.DarkTheme .stratName{
    color: #B2B5BE;
}

.DarkTheme .multText{
    color: #B2B5BE;
}

.details{
    display: flex;
    flex-direction: row;
    justify-content: left;
}
.detailshead{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    color: #586572;
}

.DarkTheme .detailshead{
    color: #B2B5BE;
}

.profitValue{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #26a69a;
}
.breakevensValue{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}
.bdr{
    border-bottom:1px solid #E4EAF0;
}

.lossValue{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #FE4141;
}

.imageFix{
    position: absolute;
    top: -3px;
    right: -14px;
}

.cepe1{
    align-items: center;
    padding: 2px 4px;
    width: 22px;
    height: 16px;
    background: #E4EAF0;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 500;
    position: relative;
    top: -2px;
}

.sPrice{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #586572;
}

.date{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #586572;
}

.ltp_change{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.DarkTheme .ltp_change{
    color: #B2B5BE;
}

.DarkTheme .breakevensValue{
    color: #B2B5BE;
}

.DarkTheme .bdr{
    border-bottom:1px solid #434651;
}

.DarkTheme .sPrice{
    color: #B2B5BE;
}
.DarkTheme .date{
    color: #B2B5BE;
}

.DarkTheme .multvalue{
    color: #B2B5BE;
}

.DarkTheme .cross{
    color: #B2B5BE;
}


.margin{
    background: #F6F8FC;
    border-radius: 0px 0px 12px 12px;
    height: 80px;
}

.DarkTheme .margin{
    background: #171D27;
border-top: 1px solid #434651;
border-radius: 0px 0px 12px 12px;
}

.Buttons{
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.analyse{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 40px;
    gap: 10px;
    width: 148px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #E4EAF0;
    border-radius: 90px;
}

.continue{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 40px;
    gap: 10px;
    width: 148px;
    height: 48px;
    background: #064AD3;
    border-radius: 90px;
    color: #FFFFFF;
}

.addFunds{
height: 32px;
background: #FFF4EB;
text-align: left;
display: flex;
flex-direction: row;
gap: 10px;
}

.fundWarning{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #CF6C00;
}

.addFundButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    width: 69px;
    height: 20px;
    background: #FF992A;
    border: 1px solid #FF992A;
    border-radius: 34px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #FFFFFF;
    
}

.AnalyseBreakEvens{
box-sizing: border-box;
background: #FFFFFF;
border: 1px solid #E4EAF0;
border-radius: 12px;
height: 190px;
width:426px;
}

.recharts-tooltip-wrapper {
    .custom-tooltip{
        height: 76px;
        width: 170px;
        background: #FFFFFF;
        border: 1px solid #F6F8FC;
        box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
        border-radius: 20px;
        padding: 10px;
    }
    .label{
        font-family: 'HK Grotesk';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #8392A0;
    }
    .payOffValue{
        font-family: 'HK Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        // color: #FE4141;
    }
}

.optionsLeg{
    width:426px;
    height: 116px;
    border: 1px solid #E4EAF0;
    border-radius: 12px;
}

.optionsLeg2{
    width:395px;
    height: 106px;
    border: 1px solid #E4EAF0;
    border-radius: 12px;
}

.multiplier2{
    position: relative;
    right: 0%;
    top: 6%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    text-align: center;

}

.underlying{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #586572;
}

.bottom-area{
position: absolute;
bottom: 0px;
width: 100%;
height: 80px;
background: #F6F8FC;
border-radius: 0px 0px 12px 12px;
}

.continue2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 40px;
    gap: 10px;
    width: 74%;
    height: 48px;
    background: #064AD3;
    border-radius: 90px;
    color: #FFFFFF;
    margin-left: 23%;
}

.daystoexpire{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #4683FC;
}

  .currentPrice{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    width: 141px;
    height: 22px;
    background: #E4EAF0;
    border-radius: 38px;
    position: absolute;
    top: -6px;
    left: 40%;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #171D27;
  }

  .strategyBuilder{
    width: 827px;
    height: 166px;
    background: #FFFFFF;
    border: 1px solid #E4EAF0;
    border-radius: 12px;
  }

  .DarkTheme .strategyBuilder{
    background-color:  #292E39;
    border: 1px solid #434651;
  }

  .strategyLtp{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding-right: 15px;
  }

  .DarkTheme .strategyLtp{
    color: #B2B5BE;
  }

  .builderSymbol{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #064AD3;
  }

  .predictHead{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #26313D;
  }
  .DarkTheme .predictHead{
    color:#B2B5BE;
  }

  .predictType{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    width: 122px;
    height: 44px;
    border: 1px solid #E4EAF0;
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 6px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
  }

  .DarkTheme .predictType{
    background: #323232;
    border: 1px solid #8392A0;
    color: #B2B5BE;
 }

  .predictType.active{
    background-color: #064AD3;
    font-weight: 600;
    color: #FFFFFF;
  }

  .DarkTheme .predictType.active{
    border-color: #064AD3;
  }

  .predictedValue{
    padding: 12px 12px 12px 16px;
    height: 44px;
    background: #F6F8FC;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
  }

  .DarkTheme .predictedValue{
    background: #171D27;
    border: 1px solid #434651;
    border-radius: 6px;
    color: #8392A0;
  }

  .DarkTheme .secondPredicted{
    background: #171D27;
    border: 1px solid #434651;
    border-radius: 6px;
    color: #8392A0;
  }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.builderContinue{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 40px;
    width: 215px;
    height: 48px;
    background: #064AD3;
    border-radius: 90px;
    position: absolute;
    right: 30px;
    top: 35px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.outerBox{
    height: 56px;
    border: 1px solid #E4EAF0;
    border-radius: 12px;
    padding-left: 11px;
    padding-right: 11px;
}

.DarkTheme .outerBox{
    border:1px solid #434651;
}

.predict{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
}

.pred{
    padding: 5px 10px;
    border-style: solid;
    border-color: #E4EAF0;
    width: 100px;
}

.DarkTheme .pred{
    background: #323232;
    border: 1px solid #8392A0;
    color: #B2B5BE;
 }

  .pred.active{
    background-color: #064AD3;
    font-weight: 600;
    color: #FFFFFF;
  }

  .DarkTheme .pred.active{
    border-left-color: #064AD3;
    border-right-color: #064AD3;
  }

.secondPredicted{
    display: flex;
flex-direction: row;
align-items: center;
padding: 5px 0px 5px 10px;
width: 184px;
background: #F6F8FC;
border: 1px solid #E4EAF0;
border-radius: 6px;
}

.submitButton{
    padding: 5px 10px;
    width: 130px;
    border: 1px solid #8392A0;
    border-radius: 26px;
    margin-top: 9px;
    margin-left: 17px;
    background-color: #FFFFFF;
    pointer-events: none;
}

.submitButton.enable{
    background-color:#064AD3;
    color: #FFFFFF;
    pointer-events: auto;
    border: 1px solid #064AD3;
}

.DarkTheme .submitButton.enable{
    background-color:#064AD3;
    color: #FFFFFF;
    pointer-events: auto;
    border: 1px solid #064AD3;
}

.DarkTheme .submitButton{
    background: #E4EAF0;
}


.builderTradingOpportunies{
    width: 100%;
    padding: 20px;
    height: 70%;
    overflow-y: scroll;
}

.filterWindow{
    width: 264px;
    height: 517px;
    background: #FFFFFF;
    box-shadow: -8px 0px 33px 8px rgba(24, 39, 75, 0.09);
    border-radius: 0px 12px 12px 0px;
    position: absolute;
    top: 0px;
    right: 0px;
}

.DarkTheme .filterWindow{
    background: #292E39;
    border-color: #434651;
}

.builder_filter_header{
    width: 264px;
    height: 69px;
    background: #F6F8FC;
    border-radius: 0px 12px 0px 0px;
    }
.DarkTheme .builder_filter_header{
    background: #292E39;
    border-color: #434651;
    border-bottom: 1px solid #434651;
}

.sortButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    width: 112px;
    height: 32px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.sortButton.active{
    background-color: #064AD3;
    font-weight: 600;
    color: #FFFFFF;
}

.DarkTheme .sortButton{
    background: #323232;
    border: 1px solid #8392A0;
    border-radius: 6px;
    color: #B2B5BE;
}

.DarkTheme .sortButton.active{
    background-color: #064AD3;
    font-weight: 600;
    color: #FFFFFF;
    border-color:#064AD3;
}
.strategyFilterHeader{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #26313D;
}

.DarkTheme .strategyFilterHeader{
    color: #FFFFFF;
}

.strategyFilterValue{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #171D27;
}

.DarkTheme .strategyFilterValue{
    color:#B2B5BE;
}

.checkbox input {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

.type{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 4px;
    gap: 4px;
    width: 61px;
    height: 20px;
    border-radius: 4px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #171D27;
}

.noStrategyHeader{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #171D27;
}

.noStrategyDesc{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #586572;
}

.DarkTheme .bottom-area{
    background: #292E39;
border-top: 1px solid #434651;
border-radius: 0px 0px 12px 12px;
}

.DarkTheme .optionsLeg{
    border: 1px solid #434651;
    border-radius: 12px;
}
.DarkTheme .optionsLeg2{
    border: 1px solid #434651;
    border-radius: 12px;
}

.DarkTheme .AnalyseBreakEvens{
    background: #292E39;
    border: 1px solid #434651;
    border-radius: 12px;
}

.DarkTheme .underlying{
    color: #B2B5BE;
}

.DarkTheme .builderSymbol{
    color: #B2B5BE;
}

.DarkTheme .dte{
    color:#B2B5BE
}

.custom-axis-label {
    fill: #586572;
  }

.DarkTheme .custom-axis-label {
    fill: #B2B5BE;
    margin-top: 10px;
  }

.DarkTheme .arrow{
    stroke: #8392A0;
    }

.tooltip2 .tooltiptext2 {
    visibility: hidden;
      background-color: #171D27;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 100;
      margin-left: -8px;
      opacity: 1;
      transition: opacity 0.3s;
      width: 327px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      height: 80px;
  }

  .tipArrow{
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #171D27 transparent;
      width: 10px;
  }
  
//   .tooltip2 .tooltiptext2::after {
//       content: "";
//       position: absolute;
//       top: -14%;
//       left: 89%;
//       margin-left: 15px;
//       border-width: 5px;
//       border-style: solid;
//       border-color: transparent transparent #171D27 transparent;
//   }
  
  .tooltip2:hover .tooltiptext2 {
    visibility: visible;
    opacity: 1;
  }

  .tipvalue{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
  }

  .strategyType{
    display: inline-block ;
    width: 81px;
    border-radius: 4px;
    position: absolute;
    right: 27px;
    top: 13px;
    font-weight: 700;
  }

  .DarkTheme .noStrategyHeader{
    color:#B2B5BE
  }

.tv-circle-logo{
    height: 48px;
    width: 48px;
    border-radius: 50%;
}