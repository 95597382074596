.custom-order-dialog_add_to_basket {
    position: absolute;
    top: 5%;
    left: calc(8% + 385px);
    box-sizing: border-box;
    padding: 0px 0px;
    /*border: 1px solid #E4EAF0;*/
    width: 400px;
    /*height: 650px;*/
    /*background-color: #ffffff;*/
    box-shadow: 0px 8px 33px 8px #18274B17
;
    border-radius: 12px;
    z-index: 45;
}

.DarkTheme .my_box2 {
    border: 1px solid #1e222d;
    border-radius: 12px;
    background-color: #1e222d;
}



.OverlayBasket {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #161414c9;
    overflow: hidden;
    z-index: 1050;
    outline: 0;
}

.my_box2{
    position: absolute;
    height: 630px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: white;
    border-radius: 12px;
}

.header-basket{
    height: 90px;
    border-bottom: 1px solid #E4EAF0;
    padding-top: 10px;
}

.header-basket2{
    border-bottom: 1px solid #E4EAF0;
}

.DarkTheme .header-basket2,.DarkTheme .header-basket{
    border-bottom: 1px solid #434651;
}

.header-basket2 div{
    padding: 2px 0 2px 0;
}

.buySellBx{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    width: 120px;
    height: 38px;
    background: #E4EAF0;
    border-radius: 90px;
}

.DarkTheme .buySellBx{
    background: white;
}


.buyBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 56px;
    height: 30px;
    background: #28C269;
    box-shadow: 0px 2px 2px -2px rgba(24, 39, 75, 0.04);
    border-radius: 90px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
}

.sellBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 56px;
    height: 30px;
    background: #ef3535;
    box-shadow: 0px 2px 2px -2px rgba(24, 39, 75, 0.04);
    border-radius: 90px;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;

}

.heading_light{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #8392A0;
}

.m_text{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #171D27;
}
.green_text{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #28C269;
}

.second_heading{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #171D27;
}

.heading_light_thin{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8392A0;
}

.small_m{
    margin-top: 2px;
}

.active_border{
    border-bottom: 2px solid #171D27;
    /*margin-bottom: -10px;*/
}

.DarkTheme .active_border{
    border-bottom: 2px solid #fcfcfc;
    /*margin-bottom: -10px;*/
}

.header-basket2 > div:hover {
    background-color: #efefef;
    cursor: pointer;
    /*margin-bottom: -9px;*/
}

.DarkTheme .header-basket2 > div:hover {
    background-color: #292E39;
    cursor: pointer;
    /*margin-bottom: -9px;*/
}


.padding15{
    padding: 15px;
}

.help_text{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #26313D;
}

.limit_market{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 150px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    color: #064AD3;

    font-family: 'HK Grotesk', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

.DarkTheme .limit_market{
    background-color: #323232;
    color: white;
    border: 1px solid #8392A0;
}

.only_limitBox{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    width: 65px;
    height: 45px;
    background: #064AD3;
    border: 1px solid #064AD3;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    font-family: 'HK Grotesk', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

.DarkTheme .only_limitBox{
    background: white;
    color: black;
    border: 1px solid #8392A0;
}

.bg_limit{
    background-color: #064AD3;
    border-radius: 6px 0 0 6px;
    color: white;
}
.bg_market{
    background-color: blue;
    border-radius: 0 6px 6px 0;
    color: white;
}

.DarkTheme .bg_limit ,.DarkTheme .bg_market{
    background-color: white;
    color: black;
    border: 1px solid #8392A0;
}

.validity_box{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    width: 59px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    cursor: pointer;
}

.active_v_box{
    background: #064AD3;
    border: 1px solid #064AD3;
    color: white;
}

.DarkTheme .validity_box{
    background-color: #323232;
    color: white;
    border: 1px solid #8392A0;
}

.DarkTheme .active_v_box{
    background-color: white;
    color: black;
}

.add_basket_btn{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: #064AD3;
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.required_margin{
    position: absolute;
    height: 60px;
    width: 100%;
    bottom: 50px;
    background: #F6F8FC;
}

.text_grey{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #586572;
}

.scroll_box{
    height: 380px;
    overflow-y: auto;
}

.heading_stock_name{
    font-family: 'HK Grotesk', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #171D27;
}



@media screen and ( max-height: 700px ) {

    .my_box2{
      height: 550px;
    }

    .scroll_box{
        height: 305px;
    }

}
