@import '../../../scss/colors';

@mixin common-styles($border-color, $text-color) {
  border: 1px solid $border-color;
  color: $text-color;
}

.search-bar {
  @include common-styles(#d0d9e2, $tv-gray-dark);
}

.DarkTheme .search-bar {
  @include common-styles(#8392a0, white);
}

.alert-main-input {
  @include common-styles(#d0d9e2, $tv-gray-dark);
}
/* Placeholder color for normal theme */
.alert-main-input::placeholder {
  color: #A1A1A1; /* Example placeholder color for normal theme */
}

/* Placeholder color for dark theme */
.DarkTheme .alert-main-input::placeholder {
  color: #B0BAC6; /* Example placeholder color for dark theme */
}


.DarkTheme .alert-main-input {
  @include common-styles(#8392a0, white);
}

.create-or-edit-dropdown {
  height: 40px;
  @include common-styles(#d0d9e2, $tv-gray-dark);
}

.DarkTheme .create-or-edit-dropdown {
  @include common-styles(#8392a0, white);
  background-color: #323232;
}


.font-16 {
  font-size: 16px;
  line-height: 20px;
}

.text-color {
  color: $tv-gray-dark;
}

.DarkTheme .text-color {
  color: white;
}

.alerts-create-edit-background {
  background-color: #F6F8FC;
  border-top: 1px solid #E4EAF0;
  border-bottom: 1px solid #F6F8FC;
  border-radius: 0 0 10px 10px;
}

.DarkTheme .alerts-create-edit-background {
  background-color: #292E39;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  border-bottom: 1px solid #434651;
}

.border-right-0{
  border-right: 0 !important;
}

.border-left-0{
  border-left: 0 !important;
}

.btn-alert {
  padding: 12px 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-radius: 25px;

  &.primary {
    background-color: $candle-blue;
    color: white;
    border: 0;
  }

  &.secondary {
    background-color: $candle-white;
    border: 1px solid #ccc;
    color: $candle-blue;
  }
}



.br_12{
  border-radius: 12px;
}

.bg-gray{
  background: #F6F8FC;
}

.DarkTheme .bg-gray{
  background: #26313D;
}


.fw_700{
  font-weight: 700;
}

.fs-14{
  font-size: 14px;
}
// CSS for Disabled Input
.alerts-disabled{
  background-color: #F6F8FC !important;
  color: #B0BAC6;
  opacity: 0.5;
}

.DarkTheme .alerts-disabled{
  background-color: #394654 !important;
  color: #B0BAC6;
  opacity: 0.5;
}

.DarkTheme .alerts-disabled::placeholder {
  color: #B0BAC6 !important;
  opacity: 1 !important;
}

.alerts-disabled::placeholder {
  color: #B0BAC6 !important;
  opacity: 1 !important;
}


.alert-name-input {
  @include common-styles(#d0d9e2, $tv-gray-dark);
}

/* Placeholder color for normal theme */
.alert-name-input::placeholder {
  color: #A1A1A1; /* Example placeholder color for normal theme */
}

/* Placeholder color for dark theme */
.DarkTheme .alert-name-input::placeholder {
  color: #B0BAC6; /* Example placeholder color for dark theme */
}


.DarkTheme .alert-name-input {
  @include common-styles(#8392a0, white);
}

.DarkTheme .max-20-alerts{
  color: #D0D9E2;
}

 .max-20-alerts{
  color: #26313D;
}