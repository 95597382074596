.btn-base {
  font-weight: bold;
  font-size: 0.875rem; /* 14px */
  line-height: 1.125rem; /* 18px */
  padding: 10px 16px;
  border-radius: 0.375rem; /* 6px */
}

.focus-reset {
  &:focus {
    outline: none; 
    box-shadow: none;
  }
}
