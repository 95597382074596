/* Make the Dropdown Toggle button transparent */
.custom-toggle {
    background-color: transparent !important;  /* Set background to transparent */
    border: 1px solid transparent !important;  /* Remove the border */
    color: black !important;  /* Set the text color to black */
    box-shadow: none !important;  /* Remove any box-shadow or border focus state */
    outline: none !important;  /* Remove the focus outline */
    
    /* font size, line height, and weight */
    font-size: 20px !important;  /* Set font size to 20px */
    line-height: 24px !important;  /* Set line height to 24px */
    font-weight: 700 !important;  /* Set font weight to 700 (bold) */
  }

  /* Dark theme styles for the toggle */
.DarkTheme .custom-toggle {
    background-color: transparent !important;  /* Set background to transparent */
    border: 0 !important;  /* Remove the border */
    color: white !important;  /* Set the text color to white */
  }
  
/* Optional: Ensure the dropdown button doesn't gain focus styling when clicked */
.custom-dropdown:focus-within .custom-toggle {
    box-shadow: none !important;  /* Prevent focus box-shadow */
  }

  
  /* Make the Dropdown menu background white */
.custom-menu {
    border-radius: 8px !important;  /* Add border radius for rounded corners */
    background-color: white !important;  /* Set menu background to white */
    border: 1px solid #E4EAF0 !important;  /* Add border for visibility */
    font-size: 14px !important;  /* Set font size to 14px */
    line-height: 16px !important;  /* Set line height to 16px */
    font-weight: 600 !important;  /* Set font weight to 600 (Semibold) */
  }
  
  /* Dark theme styles for the menu */
  .DarkTheme .custom-menu {
    background-color: #242424 !important;  /* Set menu background to dark */
    border: 1px solid #26313D !important;  /* Add darker border for contrast */
    box-shadow: 0px 6px 12px -6px #18274B1F !important;
  }

  /* Style the Dropdown menu items */
.custom-item {
    font-size: 14px !important;  /* Set font size to 14px */
    font-weight: 600 !important;  /* Set font weight to 600 (Semibold) */
    line-height: 18px !important;  /* Set line height to 18px */
    padding: 12px 24px !important;  /* Set padding to 12px 16px */
  
    /* Set initial background color for items */
    color: #171D27 !important;  /* Set text color to white */
    background-color: white !important;  /* Initial background color for visibility */
  }
  
  /* Dark theme styles for the items */
  .DarkTheme .custom-item {
    color: white !important;  /* Set text color to white */
    background-color: #26313D !important;  /* Set dark background for items */
  }
  
  /* Change the item background color to gray when hovered */
  .custom-item:hover {
    background-color: #F6F8FC !important;  /* Set hover background to gray */
  }
  
  .DarkTheme .custom-item:hover {
    background-color: #555d66 !important;  /* Set hover background to gray */
  }

  .custom-dropdown .custom-toggle {
    display: flex;
    align-items: center; // This centers the icon and text vertically
    justify-content: center; // This centers the content horizontally
    padding: 0.375rem 1rem; // Adjust padding if needed
  }
  
  .custom-dropdown .custom-toggle .dropdown-toggle::before {
    margin-right: 0.5rem; // Adjust the space between the text and icon
  }
  
  .custom-dropdown .custom-menu {
    padding: 0;
    overflow: hidden;
  }
  