.form-label {
     margin-bottom: 0.2rem;
}

.blue_btn_outline{
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: stretch;
     padding: 8px 40px;
     background: #ffffff;
     border-radius: 90px;
     border: 1px solid #064AD3;;
     color: #064AD3;
     width: 100%;
}

.blue_btn{
     display: flex;
     align-items: center;
     flex-direction: row;
     justify-content: center;
     align-items: stretch;
     padding: 8px 40px;
     background: #064AD3;
     border-radius: 90px;
     color: white;
     width: 100%;
}
.blue_btn:hover{
     color: white;
}

.css_input_box2 {
     border-width: 1px;
     border-color: #949495;
     background-color: #e5eef6;
     color: #181717;
     border-style: solid;
     border-radius: 5px;
     width: 3rem !important;
     height: 3rem;
     margin: 5px;
     font-size: 15px;
     text-align: center;
}


.grey_btn{
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     padding: 8px 40px;
     background: #ffffff;
     border-radius: 90px;
     border: 1px solid #D0D9E2;
     color: #171D27;
}

.step_badge{
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     padding: 2px 8px;
     background: #DDE8FF;
     border-radius: 12px;
     width: 60px;
     font-weight: 500;
     font-size: 12px;
     color: #26313D;
}

.heading2{
     font-family: 'HK Grotesk';
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     color: #171D27;
}

.QRcode_box{
     width: 167px;
     height: 167px;
     border: 1px solid #D0D9E2;
     border-radius: 8px;
     margin-left: 20px;
}

.copyKey{
     position: absolute;
     right: 3px;
     top: 4px;
     box-sizing: border-box;
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     padding: 4px 8px;
     width: 80px;
     height: 35px;
     background: #064AD3;
     border: 1px solid #064AD3;
     box-shadow: 0px 2px 2px -2px rgba(24, 39, 75, 0.04);
     border-radius: 8px;
     color: white;
     font-weight: 500;
     cursor: copy;
}

.center_loading {
     position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     text-align: center;
     padding: 10px;
     z-index: 100;
}

@media screen and (max-width: 768px){
     .QRcode_box{
          width: 130px;
          height: 130px;
     }

     .heading2{
      font-size: 14px;
     }

}

.css_input_box2 {
     border-width: 1px;
     border-color: #949495;
     background-color: #e5eef6;
     color: #181717;
     border-style: solid;
     border-radius: 5px;
     width: 3rem !important;
     height: 3rem;
     margin: 5px;
     font-size: 15px;
     text-align: center;
}


.grey_btn{
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     padding: 8px 40px;
     background: #ffffff;
     border-radius: 90px;
     border: 1px solid #D0D9E2;
     color: #171D27;
}

.step_badge{
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     padding: 2px 8px;
     background: #DDE8FF;
     border-radius: 12px;
     width: 60px;
     font-weight: 500;
     font-size: 12px;
     color: #26313D;
}

.heading2{
     font-family: 'HK Grotesk';
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     color: #171D27;
}

.QRcode_box{
     width: 167px;
     height: 167px;
     border: 1px solid #D0D9E2;
     border-radius: 8px;
     margin-left: 20px;
}

.copyKey{
     position: absolute;
     right: 3px;
     top: 4px;
     box-sizing: border-box;
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     padding: 4px 8px;
     width: 80px;
     height: 35px;
     background: #064AD3;
     border: 1px solid #064AD3;
     box-shadow: 0px 2px 2px -2px rgba(24, 39, 75, 0.04);
     border-radius: 8px;
     color: white;
     font-weight: 500;
     cursor: copy;
}

.center_loading {
     position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     text-align: center;
     padding: 10px;
     z-index: 100;
}
.qr_image{
     height: 230px;
     width: 230px;
     position:relative;
}
.qr_logo{
     position: relative;
     right: 139px;
     top: 0px;
     background-color:#fff;
    border:2px solid #064AD3;    
    height:50px;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:50px;
    padding: 2px;
}
.qr_center{
     display: flex;
     flex-direction: row;
     justify-content: center;
     margin-left: 4em;
}

/* .qr_code{
     display: flex;
     flex-direction: column;
     justify-content: center;
     margin-left: 4em;
} */
.qr_log_switch{
     font-family: 'HK Grotesk';
font-style: normal;
font-weight: 800;
font-size: 15px;
line-height: 16px;
color: #064AD3;
cursor: pointer;
}
.qr_log_switch_login{
     font-family: 'HK Grotesk';
font-style: normal;
font-weight: 800;
font-size: 15px;
line-height: 16px;
color: #064AD3;
cursor: pointer;
margin-left: 8em;
}

.qr_inst{
font-family: 'HK Grotesk';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 20px;
color: #171D27;
}
.permission_box{
     margin-top: 20px;
     width: 420px;
     padding-bottom: 12px;
}

.permission-list-item{
     padding-top: 0.5rem;
 }

 @media screen and (max-height: 800px){
     .card_padding {
         padding: 10px 35px !important;
     }
}

@media screen and (max-width: 768px){
     .QRcode_box{
          width: 130px;
          height: 130px;
     }

     .heading2{
      font-size: 14px;
     }

}

.disclosure{
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     box-sizing: border-box;
     padding: 0px 0px;
     border: 1px solid #E4EAF0;
     width: 870px;
     height: 600px;
     background: #FFFFFF;
     box-shadow: 0px 8px 33px 8px #18274B17
;
     border-radius: 12px;
     z-index: 50;
     opacity: 1;
}

.disclosureLayer{
     position: absolute;
     top: 0px;
     height: 100%;
     width: 100%;
     opacity:0.5;
     background-color: black;
     z-index: 40;
}

.discFooter{
     background: var(--background-grey-100, #F6F8FC);
     padding: 0rem 0.5rem 6rem;
     position: absolute;
     bottom: 0px;
     width: 100%;
     height: 80px;
     border-radius: 0px 0px 12px 12px;
}

.ackButton{
     display: flex;
     position: absolute;
     right: 30px;
     top:19px;
     width: 176px;
     padding: 14px 31px;
     justify-content: center;
     align-items: center;
     gap: 10px;
     border-radius: 90px;
     background: var(--primary-primary-default, #064AD3);
     height: 51px;
     font-size: 16px;
     font-family: HK Grotesk;
     font-style: normal;
     font-weight: 500;
     line-height: 20px;
     color: #FFFFFF;
}

.discHeader{
     color:#171D27;
     font-size: 32px;
     font-family: HK Grotesk;
     font-style: normal;
     font-weight: 700;
     line-height: 36px;
}

.discContent{
     margin-top: 8%;
     margin-left: 5%;
     margin-right: 5%;
 }

 .pointers-flex{
     display: flex;
     flex-direction: row;
     color: #171D27;
     font-size: 16px;
     font-family: HK Grotesk;
     font-style: normal;
     font-weight: 500;
     line-height: 20px;
 }

 .infoHead{
     color: #586572;
     font-size: 16px;
     font-family: HK Grotesk;
     font-style: normal;
     font-weight: 700;
     line-height: 20px;
 }

 .discInfo{
     border-radius: 12px;
     background: #F6F8FC;
     height: 109px;
     margin-top: 66px;
     padding-top: 18px;
     padding-left: 18px;
     padding-right: 18px;
 }

 .hyperlink{
     padding-top: 10px; 
     color:  #064AD3;
     font-size: 16px;
     font-family: HK Grotesk;
     font-style: normal;
     font-weight: 500;
     line-height: 20px;
 }

 .downloadHeader{
     background: #FFEEDC;
     border-radius: 12px;
     padding: 20px;
 }

 .downloadHead{
     color: var(--background-grey-900, #171D27);
     font-family: HK Grotesk;
     font-size: 20px;
     font-style: normal;
     font-weight: 700;
     line-height: 20px;
 }

 .downloadtail{
     color: var(--background-grey-900, #171D27);
     font-family: HK Grotesk;
     font-size: 14px;
     font-style: normal;
     font-weight: 400;
     line-height: 18px;
 }

 .middleHead{
     color: var(--background-grey-800, #26313D);
     font-family: HK Grotesk;
     font-size: 16px;
     font-style: normal;
     font-weight: 700;
     line-height: 28px;
 }

 .middleDesc{
     font-family: 'HK Grotesk';
     font-style: normal;
     font-weight: 600;
     font-size: 15px;
     line-height: 18px;
     color: #8392A0;
 }

 .mobileApp{
     display: flex;
     flex-direction: row;
     gap: 10px;
     justify-content: center;
     align-items: center;
     color: #202020;
     font-family: HK Grotesk;
     font-size: 24px;
     font-style: normal;
     font-weight: 700;
     line-height: 24px;
     margin-top: 20px;
 }

 .downloadButton{
     display: flex;
     height: 48px;
     padding: 8px 40px;
     justify-content: center;
     align-items: center;
     gap: 10px;
     flex: 1 0 0;
     border-radius: 90px;
     background: var(--primary-primary-default, #064AD3);
     cursor: pointer;
     color: #FFFFFF;
 }

 .clickComp{
     display: flex;
     flex-direction: row;
 }
 .clickFeatures{
     width:48%;
     cursor:pointer;
     justify-content: center;
     align-items: center;
     border-radius:15px;
 }
.activeFeature{
     border:1px solid black;

}
 .logoText{
     font-weight: 800;
 }

 .dot-container {
     display: flex;
     gap: 10px;
     justify-content: center;
     margin-top: 20px;
 }
 .cr-dot{
     height: 8px;
     width: 8px;
     border-radius: 50%;
     display: inline-block;
 }

 #first-dot{
     background-color: grey;
 }

 #second-dot{
     background-color: lightgrey;
 }

 .kra-info{
     color: #171D27;
     font-size: 16px;
     font-family: HK Grotesk;
     font-style: normal;
     font-weight: 500;
     line-height: 20px;
 }

 .a-text{
    color: #064AD3;
 }