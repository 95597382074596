.skeleton {
    background-color: #f0f0f0; /* Very light gray for light mode */
    border-radius: 8px; /* Slightly rounded corners */
    position: relative;
    overflow: hidden;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Light shadow for depth */
}

.skeleton::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(135deg, rgba(240, 240, 240, 0.7) 0%, rgba(255, 255, 255, 0.8) 40%, rgba(255, 255, 255, 1) 100%);
    animation: shimmer 1.5s ease-in-out infinite;
}

/* Dark theme adjustments */
.DarkTheme .skeleton::before {
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 60%, rgba(255, 255, 255, 0) 100%);
}

/* Keyframe animation for shimmer effect */
@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(200%);
    }
}

/* Media query for light mode */
@media (prefers-color-scheme: light) {
    .skeleton {
        background-color: #f9f9f9; /* Even lighter gray for light mode */
    }

    .skeleton::before {
        background: linear-gradient(135deg, rgba(245, 245, 245, 0.6) 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 1) 100%);
    }
}

/* Optional: Add more subtle shading effects */
.skeleton.shadow {
    background-color: #eaeaea; /* Slightly darker light background */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.05);
}
