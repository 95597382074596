.DarkTheme .exchnage_pill_bg {
  background: #e4eaf0;
}

.exchnage_pill_bg {
  background: #e4eaf0;
}

.font-10 {
  font-size: 8px;
}

.text-contrast {
  color: #171d27;
}

.br-2 {
  border-radius: 4px;
}

.padding_y_2 {
  padding-top: 2px;
  padding-bottom: 2px;
}
