.my_bg{
    background-color: #064AD3;
    height:  100vh;
    width: 100%;
    background-image: url("../../assets/images/bg_man.svg");
    background-repeat: no-repeat;
    background-position: 40% 100%;
    background-size: 68%;
    overflow-y: hidden;
}

.my_bg_themed{
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    height:  100vh;
    width: 100%;
    overflow-y: hidden;
}


.center_card{
    display: grid;
    place-items: center;
}

.developer_details{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topHeader{
    position: absolute;
    width: 100%;
    height: 140px;
    /*background-color: #c7c7ef;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
}

#footer {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    bottom: 10px;
    z-index: 1;
}

.footer_text{
    color: #D0D9E2;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.box_body{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    background: #FFFFFF;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
    border-radius: 24px;
    z-index: 2;
}

.my_body_padding1{
    padding: 4.5rem;
}

.otp_box{
    /* width: 450px!important; */
    min-height: 390px;
    /*box-shadow: -4px -3px 20px 10px rgb(211 212 214);*/
}

.my_bg_themed .border_and_shadow{
    border: 1px solid #E4EAF0;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12);
}

.enable_totp_box{
    width: 500px;
    height: 600px;
}

.passwordNeedChange{
    height: 600px;
}

.footer_text_m{
    color: white;
}

.my_bg_themed .footer_text_m{
    color: black;
}

#footer_m{
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    bottom: 10px;
    z-index: 1;
}

@media screen and (max-height: 800px ) {
    .topHeader .heading1{
        font-size: 20px;
    }
    .topHeader .heading3{
        font-size: 14px;
    }
}
@media screen and (max-width: 760px){

    .box_body{
        width: 100%;
        min-width: auto;
        top: 55%;
        /* height: 100%; */
        border-radius: 0px;
    }
    .my_bg{
        background-color: white;
        transform: translate(-0%, -0%)
    }

    .topHeader{
        pointer-events: none;
    }

    .topHeader img{
        width: 40px;
        margin-bottom: 5px;
    }
    .topHeader .heading1{
        font-size: 20px;
    }
    .topHeader .heading3{
        font-size: 14px;
    }
    #footer{
        display: none;
    }
    #footer_m{
        position: fixed;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        bottom: 10px;
        z-index: 5;
    }
    .footer_text_m{
        color: #000000;
    }

    .topHeader{
        margin-top: 8px;
    }



}

@media screen and (max-height: 450px) {
    #footer_m{
        display: none;
    }
}

@media screen and (max-height: 600px ) {
    .topHeader1{
        display: none;
    }
    .topHeader{
        display: none;
    }
}

@media screen and (max-height: 710px) {
    .topHeader{
        height: 90px;
    }
}

