
.discContent{
    margin-top: 8%;
    margin-left: 5%;
    margin-right: 5%;
}

.rebrandingtext{
    color: var(--background-grey-900, #171D27);
    font-family: HK Grotesk;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 130% */
    padding-left: 40px;
    padding-right: 100px;
}

.noteSection{
    border-radius: 12px;
    background: var(--background-grey-100, #F6F8FC);
    width: 600px;
    height: 124px;
    margin-left: 40px;
}

.noteHeading{
    color: var(--background-grey-700, #586572);
    font-family: HK Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    padding-top: 16px;
    padding-left: 16px;
}

.bottom{
    color: var(--background-grey-900, #171D27);
    font-family: HK Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    padding-left: 40px;
}

.impUrlLayout{
    display: flex;
    flex-direction: row;
    gap:50px;
}

.urlLayout{
    display: flex;
    flex-direction: row;
    gap:6px;
    justify-content: center;
    align-items: center;
}

.urls{
    color: var(--primary-primary-default, #064AD3);
    font-family: HK Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.urls a:hover{
    color: #064AD3;
}

.mobileLayer{
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    opacity:0.5;
    background-color: black;
    z-index: 40;
}
.mobilePopup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 0px 0px;
    border: 1px solid #E4EAF0;
    width: 100%;
    height: 400px;
    background: #FFFFFF;
    box-shadow: 0px 8px 33px 8px #18274B17
;
    border-radius: 6px;
    z-index: 50;
    opacity: 1;
}

.mobileHead{
    color: var(--background-grey-900, #171D27);
    font-family: HK Grotesk;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
}

.mobilenoteSection{
    border-radius: 12px;
    background: var(--background-grey-100, #F6F8FC);
    width: 80%;
}

.mobileFooter{
    background-color: #F6F8FC;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.gotit{
    display: flex;
    height: 48px;
    padding: 8px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 90px;
    background: var(--primary-primary-default, #064AD3);
    cursor: pointer;
    color: #FFFFFF;
}