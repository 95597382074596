body{
	font-family: "HK Grotesk";
}
.App {
	text-align: center;
	height: 100vh;
}

.App-header {
	display: flex;
	justify-content: center;
	align-items: center;
	/*padding: 10px 0;*/
	background-color: #222;
	color: #fff;
}

.App-tv-logo {
	height: 45px;
}

.App-react-logo {
	display: block;
	height: 62px;
}

.App-title {
	display: block;
	font-size: 1.5em;
}

.DarkTheme .currentBg{
	background-color: #121212;
}

.DarkTheme .currentBg2{
	background-color: #1e222d;
}

.DarkTheme .currentText{
	color: #b2b5be;
}

.DarkTheme .currentText2{
	color: #D0D9E2;
}

.DarkTheme .heading2{
	color: #b2b5be;
}

.DarkTheme .currentColor{
	color: #FFFFFF;
}

.DarkTheme .CurrentIconColor{
	fill: #b2b5be;
}
.DarkTheme .CurrentIconColor2{
	fill: #D0D9E2;
}

.CurrentIconColor,.CurrentIconColor2{
	fill: #586572;
}

.iconStockColor{
	stroke: #586572;
}

.DarkTheme .iconStockColor{
	stroke: #586572;
}

.DarkTheme .form-control:disabled, .form-control[readonly]{
	background-color: #3d3e41;
     cursor: not-allowed;
}


.DarkTheme .form-control{
	background-color: #1E222D;
	color: #D0D9E2;
	border: 1px solid #434651;
}

.DarkTheme .card_bg{
	background-color: #292E39;
}

.DarkTheme #badge_exch{
	background-color: #4b5464;
	color: white;
	font-weight: 500;
}

.DarkTheme .my_badge_topic{
	background-color: #323232;
	color: white;
	border: 1px solid #d1d4dc;
}

.DarkTheme .active{
	background-color: white;
	color: black;
}

.DarkTheme ::placeholder {
	color: #434651;
	font-weight: 600;
}

.DarkTheme .help_text{
	color: white;
}

.DarkTheme .custom-order-dialog__header{
    background: #171D27;
}

.DarkTheme #custom-order-dialog__stock-name-id {
    color: #d1d4dc;
}

.DarkTheme .custom-order-dialog__quote-ltp{
    color: #d1d4dc;
}
.DarkTheme .custom-order-dialog__side-switch-description{
    color: #d1d4dc;
}
.DarkTheme .custom-order-dialog__amo-switch-description{
    color: #d1d4dc;
}

.DarkTheme .w3-half{
    background: #171D27;
    color: #d1d4dc;
}
.DarkTheme .w3-third{
    background: #171D27;
    color: #d1d4dc;
}

.DarkTheme .custom-order-dialog__qty-input-label{
    color: #d1d4dc;
}
.DarkTheme .custom-order-dialog__price-input-label{
    color: #d1d4dc;
}
.DarkTheme .custom-order-dialog__qty-input{
    background-color:#171D27;
    color: #d1d4dc;
    border: 1px solid #d1d4dc;
}

.DarkTheme .custom-order-dialog__price-input{
    background-color:#171D27;
    color: #d1d4dc;
    border: 1px solid #d1d4dc;
}

.DarkTheme .custom-order-dialog{
    background-color:#171D27;
}

.DarkTheme .custom-order-dialog__qty-input-lot-size{
    color: #d1d4dc;
}
.DarkTheme .custom-order-dialog__price-dpr{
    color: #d1d4dc;
}
.DarkTheme .custom-order-dialog__sl-switch-description{
    color: #d1d4dc;
}
.DarkTheme .custom-order-dialog__price-input:disabled{
    color: #ffffff;
    background: #323232;
}
/* .DarkTheme #order-type-2{
    color: #FFFFFF;
} */
.DarkTheme #order-type-2:hover{
    color: #000000;
    background: #d1d4dc;
}
.DarkTheme .tabs.is-toggle a{
    background-color: #323232;
    color: #FFFFFF;
}
.DarkTheme .tabs.is-toggle li.is-active a{
    background-color: #FFFFFF;
    color: #171D27;
}
/* .DarkTheme #order-type-1{
    color: #FFFFFF;
} */

.DarkTheme #order-type-1:hover{
    color: #171D27;
    background: #d1d4dc;
}

.DarkTheme .custom-order-dialog__validity-type-header{
    color: #d1d4dc;
}
.DarkTheme .custom-order-dialog__margin-div{
    background: #171D27;
    color: #d1d4dc;
}
.DarkTheme .custom-order-dialog__sl-value-input-label{
    color: #d1d4dc;
}

.DarkTheme .state_order_background{
	background-color:#171D27;
}

.DarkTheme .order-bottombar-selected{
    border: 0.5px solid #d1d4dc;
}

.DarkTheme .order-bottombar{
    border-bottom: 0;
}

.DarkTheme .custom-order-dialog__sl-value-input{
    background-color: #171D27;
    color: #d1d4dc;
    border: 1px solid #d1d4dc;
}

.DarkTheme #custom-order-dialog__exchange-id{
	margin-left: 8px;
	text-align: center;
	justify-content: center;
	padding: 2px 4px;
	width: 28px;
	height: 16px;
	background: #d1d4dc;
	border-radius: 2px;
	font-family: 'HK Grotesk';
	font-style: normal;
	font-weight: 400;
	font-size: 8px;
	line-height: 12px;
	color: #171D27;
}
.DarkTheme .switch[type=checkbox].is-rounded + label::before, .switch[type=checkbox].is-rounded + label:before{
    background-color: #323232;  
}

.DarkTheme .switch[type=checkbox].is-large:checked + label::before, .switch[type=checkbox].is-large:checked + label:before{    
    background: #FFFFFF;
}


.cursor-not-allowed{
	cursor: not-allowed;
}

/* Frequentley Used Classes */

.font-10{
	font-size: 10px;
}

.font-12{
	font-size: 12px;
}

.font-14{
	font-size: 14px;
}

.font-16{
	font-size: 16px;
}

.font-18{
	font-size: 18px;
}

.font-20{
	font-size: 20px;
}