.greenText1{
    font-style: normal;
    color: #28C269;
    display: inline;
}
.redText1{
    font-style: normal;
    color: #FE4141;
    display: inline;
}

.green_icon{
    color: #28C269;
}

.red_icon{
    color: #FE4141;
}
