$candle-red: #F23645; 
$candel-red-hover: #CD2F3B;

$candle-green: #089981;
$candle-green-hover: #08836e;

$candle-blue: #2962FF;
$candel-blue-hover: #1E53E5;

$candle-white: #FFFFFF;
$candle-black: #131722;

$tv-gray: #E0E3EB;
$tv-gray-dark: #2A2E39;


// --bs-blue: #0d6efd;
//     --bs-indigo: #6610f2;
//     --bs-purple: #6f42c1;
//     --bs-pink: #d63384;
//     --bs-red: #dc3545;
//     --bs-orange: #fd7e14;
//     --bs-yellow: #ffc107;
//     --bs-green: #198754;
//     --bs-teal: #20c997;
//     --bs-cyan: #0dcaf0;
//     --bs-white: #fff;
//     --bs-gray: #6c757d;
//     --bs-gray-dark: #343a40;
//     --bs-primary: #0d6efd;
//     --bs-secondary: #6c757d;
//     --bs-success: #198754;
//     --bs-info: #0dcaf0;
//     --bs-warning: #ffc107;
//     --bs-danger: #dc3545;
//     --bs-light: #f8f9fa;
//     --bs-dark: #212529;
//     --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//     --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
//     --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));