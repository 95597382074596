.transition-all {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-duration: 0.3s; /* Adjust duration for smooth transitions */
  transition-timing-function: cubic-bezier(
    0.4,
    0,
    0.2,
    1
  ); /* Smooth cubic-bezier easing */
}
