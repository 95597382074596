@import "./colors";
@import "./transitions";
@import "./animations";
@import "./helper/button.helper";

.tv-btn-success {
  background-color: $candle-green;
  transition: background-color 0.3s ease-in-out;
  color: $candle-white;
  font-weight: bolder !important;

  &:hover {
    background-color: $candle-green-hover;
    color: $candle-white;
  }
}

.DarkTheme .tv-btn-success {
  // color: $candle-black;
}

.tv-btn-danger {
  background-color: $candle-red;
  transition: background-color 0.3s ease-in-out;
  color: $candle-white;
  font-weight: bolder !important;

  &:hover {
    background-color: $candel-red-hover;
    color: $candle-white;
  }
}

.tv-btn-primary {
  @extend .btn-base;
  @extend .transition-all;
  @extend .pressed-animation;
  @extend .focus-reset;

  background-color: $candle-blue;
  color: white;
  border: 1px solid $candle-blue;

  &:hover {
    background-color: $candel-blue-hover; /* Darker shade on hover */
    border-color: $candel-blue-hover;
  }

  &.active {
    background-color: var(--bs-blue); /* Even darker shade when active */
    border-color: var(--bs-blue);
  }
}

/* Secondary button style */
.tv-btn-secondary {
  @extend .btn-base;
  @extend .transition-all;
  @extend .pressed-animation;
  @extend .focus-reset;

  background: transparent;
  border: 1px solid #e4eaf0;
  color: #26313d;

  &:hover {
    background-color: #e4eaf0; /* Light background on hover */
    color: var(--bs-blue);
    border-color: var(--bs-blue); /* Optional: Adjust border color on hover */
  }

  &.active {
    background-color: #e2e6ea; /* Background color when active */
    border-color: #dae0e5;
  }
}

.DarkTheme .tv-btn-secondary {
  background: transparent;
  border: 1px solid #8392a0;
  color: $candle-white;
}
