.disclosureLayer{
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    opacity:0.5;
    background-color: black;
    z-index: 40;
}

.walkthrough{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 0px 0px;
    border: 1px solid #E4EAF0;
    width:80%;
    height: 97vh;
    background: #FFFFFF;
    box-shadow: 0px 8px 33px 8px #18274B17
;
    border-radius: 12px;
    z-index: 50;
    opacity: 1;
}
.carousel-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -20%;
}

@media (max-width: 600px) {
    .iframe{
        width: 100%;
        height: 200px;
    }
  }
  @media (min-width: 601px) and (max-width: 1025px) {
    .iframe{
        width: 100%;
        height: 90px;
    }
  }
  @media (min-width: 1026px) and (max-width: 1280px) {
    .iframe{
        width: 100%;
        height: 28vh;
    }
  }

  @media (min-width: 1281px){
    .iframe{
        width: 100%;
        height: 40vh;
    }
  }

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.walkheader{
    font-family: HK Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    padding-left: 24px;
    padding-top: 22px;
    padding-bottom: 22px;
    text-align: left;
}
.DarkTheme .header{
    background: #171D27;
    color: #b2b5be;
}

.closeWalk{
    padding-top: 22px;
    padding-bottom: 22px;
    padding-right: 24px;
}

.walkfooter{
    height: 80px;
    border-radius: 0px 0px 12px 12px;
    background: #F6F8FC;
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.DarkTheme .walkfooter{
    background: #171D27;
    color: #b2b5be;
}
.DarkTheme .walkthrough{
    background: #171D27;
    color: #b2b5be;
}

.DarkTheme .contentHead{
    color: #b2b5be;
}
.DarkTheme .contentBody{
    color: #b2b5be;
}
.nextButton{
    display: flex;
    width: 171px;
    height: 48px;
    padding: 8px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 90px;
    background: #064AD3;
    position: absolute;
    top: 15px;
    right: 19px;
    color: #FFFFFF;
}


.text-carousel {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-content {
    width: 100%;
    text-align: left;
    padding-left: 24px;
    margin-top: 24px;
    padding-right:24px;
  }
  
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #bbb;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #333;
  }

  .contentHead{
    color: #000;
    font-family: HK Grotesk;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 10px;
  }
  
  .StartWalkthrough{
    display: flex;
    height: 48px;
    padding: 8px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 90px;
    background: #064AD3;
    color: #FFFFFF;
  }

.customtooltip.introjs-tooltip{
	width: 345px;
	max-width: 600px;
}


.customtooltip .introjs-tooltiptext{
    padding-left: 17px;
    padding-bottom: 27px;
    padding-top: 10px;
    padding-right: 10px;
}

.customtooltip .introjs-tooltipbuttons{
    border-radius: 0px 0px 12px 12px;
    background: #F6F8FC;
    border-top: none;
}

.customtooltip .introjs-tooltipbuttons .introjs-nextbutton{
    display: flex;
    padding: 8px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 90px;
    background: #064AD3;
    text-shadow: none;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    width: 18px;
}
.customtooltip .introjs-tooltipbuttons .introjs-prevbutton{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 40px;
    padding-left: 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 90px;
    border: 1px solid #064AD3;
    background-color: #FFFFFF;
    width: 23px;
}

.customtooltip .introjs-helperNumberLayer{
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 50px;
    position: absolute;
    top: 11px;
    left: 17px;
    background: #E3FEEF;
    color: #1F9A53;
    border-radius: 4px;
}

.customtooltip .introjs-arrow {
    border: 12px solid transparent;
    content: "";
    position: absolute;
}

.customtooltip .introjs-arrow.top {
    top: -24px;
    left: 10px;
    border-bottom-color: #fff;
}
.customtooltip .introjs-arrow.bottom {
    bottom: -23px;
    border-top-color: #fff;
}
.customtooltip .introjs-arrow.bottom {
    bottom: -23px;
    border-top-color: #fff;
}
.customtooltip .introjs-arrow.left {
    left: -22px;
    border-right-color: #fff;
}
.customtooltip .introjs-arrow.top-middle {
    top: -24px;
    border-bottom-color: #fff;
}
.customtooltip .introjs-arrow.top-right {
    top: -24px;
    border-bottom-color: #fff;
}

.customtooltip .introjs-skipbutton{
    color: #064AD3;
    font-family: HK Grotesk;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    top: 6px;
    right: 10px;
    height: 0px;
    width: 108px;
}

.contentBody{
    color: #000;
    font-family: HK Grotesk;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

//WalkThrough Darktheme
.customtooltipDark.introjs-tooltip{
	width: 345px;
	max-width: 600px;
    background-color: #171D27;
    border: 1px solid #b2b5be;
}


.customtooltipDark .introjs-tooltiptext{
    padding-left: 17px;
    padding-bottom: 27px;
    padding-top: 10px;
    padding-right: 10px;
}

.customtooltipDark .introjs-tooltipbuttons{
    border-radius: 0px 0px 12px 12px;
    background: #171D27;
    border-top: none;
}

.customtooltipDark .introjs-tooltipbuttons .introjs-nextbutton{
    display: flex;
    padding: 8px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 90px;
    background: #064AD3;
    text-shadow: none;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    width: 18px;
}
.customtooltipDark .introjs-tooltipbuttons .introjs-prevbutton{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 40px;
    padding-left: 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 90px;
    border: 1px solid #064AD3;
    background-color: #FFFFFF;
    width: 23px;
}

.customtooltipDark .introjs-arrow {
    border: 12px solid transparent;
    content: "";
    position: absolute;
}

.customtooltipDark .introjs-arrow.top {
    top: -24px;
    left: 10px;
    border-bottom-color: #b2b5be;
}
.customtooltipDark .introjs-arrow.bottom {
    bottom: -23px;
    border-top-color: #b2b5be;
}
.customtooltipDark .introjs-arrow.bottom {
    bottom: -23px;
    border-top-color: #b2b5be;
}
.customtooltipDark .introjs-arrow.left {
    left: -22px;
    border-right-color: #b2b5be;
}
.customtooltipDark .introjs-arrow.top-middle {
    top: -24px;
    border-bottom-color: #b2b5be;
}
.customtooltipDark .introjs-arrow.top-right {
    top: -24px;
    border-bottom-color: #b2b5be;
}

.customtooltipDark .introjs-skipbutton{
    color: #FFFFFF;
    font-family: HK Grotesk;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    top: 6px;
    right: 10px;
    height: 0px;
    width: 108px;
}

.customtooltipDark .introjs-skipbutton:hover{
    color: #FFFFFF;
}

.customtooltipDark .contentBody{
    color: #b2b5be;
}

.customtooltipDark .contentHead{
    color: #b2b5be;
}