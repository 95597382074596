.green-badge{
    background-color: #DFF2EB;
    color: #29A673;
}

.red-badge{
    background-color: #FFEDED;
    color: #FE4141;
}

.font-10{
    font-size: 10px;
    line-height: 12px;
}

// common Styles

.br-6{
    border-radius: .375rem; // 6px
}