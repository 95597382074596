@import '../../../../scss/colors';

// Transition effect for background-color and color changes
.DarkTheme.toggle-background {
    background-color: #586572;
    transition: background-color 0.3s ease; // Animate background-color change
}

.toggle-background {
    background-color: $tv-gray;
    background-color: $tv-gray;
    transition: background-color 0.3s ease; // Animate background-color change
}

.DarkTheme .toggle-background {
    background-color: #586572;
    transition: background-color 0.3s ease; // Animate background-color change
}

.button-sucess {
    background-color: $candle-green;
    color: $candle-white;
    transition: background-color 0.3s ease, color 0.3s ease; // Animate both background-color and color change
}

.button-sucess:hover {
    background-color: $candle-green-hover;
    color: $candle-white; // Keep color consistent
}

.button-sell {
    background-color: $candle-red;
    color: $candle-white;
    transition: background-color 0.3s ease, color 0.3s ease; // Animate both background-color and color change
}

.button-sell:hover {
    background-color: $candel-red-hover;
    color: $candle-white; // Keep color consistent
}

.DarkTheme .button-unslected {
    color: #D0D9E2  !important;
    transition: color 0.3s ease; // Animate color change
}

.button-unslected {
    color: #586572 !important;
    background-color: transparent;
    transition: color 0.3s ease; // Animate color change
}
