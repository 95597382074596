@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css");
body {
	margin: 0;
	padding: 0;
	font-family: "HK Grotesk", serif;
	height: 100vh;
}

.heading1{
	font-family: 'HK Grotesk', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 25px;
	color: #171D27;
}

.heading2{
	font-family: 'HK Grotesk', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	color: #171D27;
}

.text1{
	font-family: 'HK Grotesk', serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #26313D;
}

.colorBlue{
	color: #064AD3;
}

.colorGrey{
	color: #586572;
}

.colorBlack{
	color: #26313D;
}

.form-control {
	line-height: 1.9;
}

.c-pointer{
	cursor: pointer;
}

.opt_box{
	width: 3rem !important;
	height: 3rem;
	margin: 5px;
	font-size: 1.3rem;
	border-radius: 8px;
	border: 1px solid #9da3a8;
}

.modal-backdrop {
	z-index: 1;
}

.border_remove{
	border-bottom: none;
}

.modal-content2{
	width: 450px;
	border-radius: 20px;
}


.modal-backdrop.show {
	opacity: .9;
}
.modal-backdrop {
	z-index: 10;
}

.model_padding{
	padding: 20px;
}

@media screen and (max-width: 768px){
	.modal-content2{
		width: 400px;
		border-radius: 15px;
	}

	.modal-content2 .colorGrey{
		font-size: 12px;
	}

	.model_padding {
		padding: 0;
	}

	.opt_box{
		width: 2.5rem !important;
		height: 2.5rem;
		margin: 3px;
		font-size: 1rem;
		border-radius: 5px;
		border: 1px solid #9da3a8;
	}

	.heading1{
		font-size: 21px;
	}

}

