@import "../../../../scss/colors";

/* Basic styling for the error text */
.error-text {
  color: $candle-red;
  font-size: 13px; // 12px
  font-weight: 600;
  border-radius: 4px;
  text-align: left;
  padding-top: .5rem;
}
