@import '../../../../../scss/colors';
/* ToggleButton.css */
.toggle-container {
    display: inline-block;
  }
  
  .toggle-group {
    display: flex;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
  }

.DarkTheme .toggle-group{
     border: 1px solid #8392a0;
}
  
  .toggle-button {
    border: none;
    background: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 4px;
    color: #D0D9E2;
    font-weight: 400;
    min-width: 80px;
    position: relative;
    outline: none;
  }
  
  // .toggle-button:hover {
  //   color: #333;
  //   background-color: rgba(0, 0, 0, 0.05);
  // }

  /* For DarkTheme and selected button */
.DarkTheme .selected-group-button {
  border-color: $candle-blue;
  background-color: $candle-blue;
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Added transition */
}

.DarkTheme .non-selected-group-button {
  border-color: #576672;
  background-color: #576672;
  color: $tv-gray;
  transition: background-color 0.3s ease, color 0.3s ease; /* Added transition */
}

/* For non-selected buttons */
.non-selected-group-button {
  background-color: #D0D9E2;
  color: $candle-black;
  transition: background-color 0.3s ease, color 0.3s ease; /* Added transition */
}

/* For active toggle button */
.toggle-button.active {
  background-color: $candle-blue;
  color: white;
  font-weight: 700 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Added transition */
}

  
  /* Optional: Add focus state for accessibility */
  .toggle-button:focus-visible {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.4);
  }
  
  /* Optional: Add disabled state */
  .toggle-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }





  // .toggle-btn-border{
  //   border: 1px solid #E4EAF0 !important;
  // }

  .DarkTheme   .toggle-btn-border{
    border: 1px solid #8392A0 !important;
  }

  .toggle-button.disabled {
    background-color: #f0f0f0;
    color: #ccc;
    cursor: not-allowed;
    border-color: #ccc;
  }
  