.futureChain{
    position: absolute;
    top: 14%;
    left: calc(18% + 56px);
    box-sizing: border-box;
    padding: 0px 0px;
    border: 1px solid #E4EAF0;
    width: 689px;
    background: #FFFFFF;
    box-shadow: 0px 8px 33px 8px #18274B17;
    border-radius: 12px;
    z-index: 40;
}

.draggable{
    cursor: all-scroll
}

.DarkTheme .futureChain{
    position: absolute;
    top: 14%;
    left: calc(18% + 56px);
    box-sizing: border-box;
    padding: 0px 0px;
    border: 1px solid #8392A0;
    width: 689px;
    background: #171D27;
    box-shadow: 0px 8px 33px 8px #18274B17
;
    border-radius: 12px;
    z-index: 40;
}

.headerSymbol{
    text-align: left;
    padding: 24px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #171D27;
}

.DarkTheme .headerSymbol{
    color: #8392A0;
}

.futureHeader{
    background: #F6F8FC;
    border-radius: 12px 12px 0px 0px;
}

.DarkTheme .futureHeader{
    background: #171D27
}

.c-pointer{
    cursor: pointer;
}

.toggleSection{
    display: flex;
    flex-direction: row;
}

.toggleButton{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    background: #FFFFFF;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    color: #171D27;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.activetoggleButton{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    background: #064AD3;
    border: 1px solid #064AD3;
    border-radius: 6px;
    color: #FFFFFF;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    }

    .tableHeader{
        display:flex;
        align-items:center;
        font-family: 'HK Grotesk';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #171D27;
    }

    .tablehlData{
            display:flex;
            align-items:center;
            font-family: 'HK Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #171D27;
    }

    .DarkTheme .tablehlData{
        color: #8392A0;
    }

    .DarkTheme .tableHeader{
        color: #8392A0;
    }

    .tabledata{
        display:flex;
        align-items:center;
        font-family: 'HK Grotesk';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #171D27;
    }

    .DarkTheme .tabledata{
        color: #8392A0;
    }

    .greenText1{
        color: #26a69a;
    }
    .redText1{
        color: #FE4141;
    }

    .headerBackground{
        background:#F6F8FC;
        border-radius: 8px;
    }

   .DarkTheme .headerBackground {
    background-color: #171D27;
    // border-bottom: 1px solid #8392A0;
   }

   .dataBackground{
    margin-left:3%;
    margin-right:3%;
    border-radius: 8px;
    background:#F6F8FC;
   }

   .DarkTheme .dataBackground{
    background: #171D27;
   }

   .mktHeader{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #171D27;
   }
   .DarkTheme .mktHeader{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #8392A0;
   }

   .notavailable{
    margin-top:15%;
    margin-bottom:15%;
    color: #171D27;
   }
   
   .DarkTheme   .notavailable{
    margin-top:15%;
    margin-bottom:15%;
    color: #8392A0;
   }

   .totalgreen{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #26a69a;
   }

   .totalred{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #FE4141;
   }

  .alignOHLC{
    text-align: left;
    padding-left: 3%;
    color: #171D27;
  }

  .DarkTheme  .alignOHLC{
    text-align: left;
    padding-left: 3%;
    color: #8392A0;
  }

  .alignMktDepth{
    text-align: left;
    padding-left: 7%;   
    padding-top: 2%;
    padding-bottom: 2%; 
  }


  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #4683FC; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-container1 {
    padding-top: 12%;
    padding-left: 45%;
    padding-bottom: 12%;
  }

  .borderabc{
    border-bottom: 1px solid #D0D9E2;
  }

  .DarkTheme .borderabc{
    border-bottom: 1px solid #595959;
  }

  .sliderLine{
    position:absolute;
    bottom:0px;
    width:96%;
    left:2%;
  }
  .sliderLine3{
    position:absolute;
    bottom:-1px;
    width:115%;
    left:0px;
    z-index: 100;
  }
  
  .lastTradedPrice{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #171D27;
    text-align: left;
    padding: 2%;
  }

  .DarkTheme .lastTradedPrice{
    color: #8392A0;
  }

  .footerBackground{
    background:#F6F8FC;
    border-radius: 0px 0px 8px 8px;
}

.DarkTheme .footerBackground{
    background:#171D27;
    border-radius: 0px 0px 8px 8px;
    }
  .slider{
    height: 4px;
    border-radius: 8px;
    background-image: linear-gradient(to right, #064AD3 , #FF8800);;
  }

  .roller{
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid #000000;
    position: absolute;
    top: -3px;
  }

