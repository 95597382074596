.DarkTheme .overallSearch{
    background-color: #292E39;
}

.overallSearch{
    background-color: white;
}


.DarkTheme .border-12{
    border: 1px solid white;
    border-radius: 12px;
}

.border-12{
    border: 1px solid #F5F5F7;
    border-radius: 12px;
}

// CSS for Condition Tab for %age
.DarkTheme .condition-dropdown-percentage{
    background-color: #3D3E41 !important;
}

.condition-dropdown-percentage{
    background-color: white !important;
}