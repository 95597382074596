.optionChain{
    position: absolute;
    top: 5%;
    left: calc(8% + 56px);
    box-sizing: border-box;
    padding: 0px 0px;
    border: 1px solid #E4EAF0;
    width: 1148px;
    background: #FFFFFF;
    box-shadow: 0px 8px 33px 8px #18274B17
;
    border-radius: 12px;
    z-index: 40;
}

.table thead td,.table thead th{
  border-width: 0 0 0;
}

// .optionChain.dark_theme{
//     position: absolute;
//     top: 5%;
//     left: calc(8% + 56px);
//     box-sizing: border-box;
//     padding: 0px 0px;
//     border: 1px solid #E4EAF0;
//     width: 996px;
//     background: #1d1d1d;
//     box-shadow: 0px 8px 33px 8px #18274B17
;
//     border-radius: 12px;
//     z-index: 40;
// }

.header{
background: #F6F8FC;
border-radius: 12px 12px 0px 0px;
}
// .header.dark_theme{
//         background-color: #242424;
//         color: #ffffff;
// }
// #MAINTABLE thead.dark_theme{
//     background-color:#121212
// }

.topSymbolName{
    font-family: 'HK Grotesk';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 24px;
color: #171D27;
display: inline;
}
// .topSymbolName.dark_theme{
//     font-family: 'HK Grotesk';
//     font-style: normal;
//     font-weight: 700;
//     font-size: 18px;
//     line-height: 24px;
//     color: #ffffff;
//     display: inline;
// }

.exchange{
    padding: 1px 4px;
    background: #E4EAF0;
    border-radius: 2px;
    display: inline;
    position: relative;
    font-size: 9px;
    bottom: 3px;
    left: 5px;
}
// .exchange.dark_theme{
//     padding: 1px 4px;
//     background: #343434;;
//     border-radius: 2px;
//     display: inline;
//     position: relative;
//     font-size: 9px;
//     bottom: 3px;
//     left: 5px;
// }

.ltp{
font-weight: 700;
font-size: 16px;
line-height: 20px;
color: #171D27;
display: inline;
padding: 1px;
}
// .ltp.dark_theme{
//     font-weight: 700;
//     font-size: 16px;
//     line-height: 20px;
//     color: #ffffff;
//     display: inline;
//     padding: 1px; 
// }
.ltp1{
    font-weight: 700;
font-size: 12px;
line-height: 20px;
color: #171D27;
display: inline;
padding-left: 3px;
}

.icon{
    display: inline;
    padding: 3px;
}


select{
    border: 1px solid #A4B1C0;
border-radius: 4px;
width: 115px;
height: 32px;
}

// .form-select.dark_theme{
//     background-color: #171D27;
//     color: #ffffff;
// }

.selectDate{
font-weight: 600;
font-size: 14px;
line-height: 30px;
color: #171D27;
}
// .selectDate.dark_theme{
//     font-weight: 600;
// font-size: 14px;
// line-height: 30px;
// color: #ffffff;
// }

#MAINTABLE thead tr .call_put{
font-family: 'HK Grotesk';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
color: #171D27;
border-bottom: 1px solid #E4EAF0;
padding-bottom: 0px;
}

// .call_put.dark_theme{
//     font-family: 'HK Grotesk';
// font-style: normal;
// font-weight: 700;
// font-size: 16px;
// line-height: 20px;
// color: #ffffff;
// border-bottom: 1px solid #E4EAF0;
// background-color: #121212;
// }

.draggable{
    cursor: all-scroll
}

#border{
    border-bottom: 1px solid #E4EAF0;
}

#topHead th{
  color: #000000;
  font-family: 'HK Grotesk';
font-style: normal;
font-weight: 900;
font-size: 15px;
line-height: 23px;
// border-bottom: 1px solid #E4EAF0;
}

#topHead{
  margin: 0px;
}


#MAINTABLE tbody tr{
line-height: 18px;
}

#MAINTABLE thead{
    position: sticky;
    top:0px;
    background-color: #FFFFFF;
    z-index: 20;
}
#MAINTABLE td{
    padding-left: 0px;
    // padding-bottom: 0px;
    padding-right: 0px;
}
#MAINTABLE th{
    // padding-left: 0px;
    padding-bottom: 0px;
    // padding-right: 0px;
}

.optionchaintable{
    width: 100%;
    overflow-x: scroll;
    height: 70vh;
}


// .optionchaintable::-webkit-scrollbar {
//     display: none;
//   }
  
//   /* Hide scrollbar for IE, Edge and Firefox */
//   .optionchaintable::-webkit-scrollbar {
//     -ms-overflow-style: none;  /* IE and Edge */
//     scrollbar-width: none;  /* Firefox */
//   }

.hidden{
    display: none;
}

.chainValue{
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #171D27;
}
// .chainValue.dark_theme{
//     font-family: 'HK Grotesk';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 12px;
//     line-height: 16px;
//     color: #ffffff;
// }

.IvValue{
    font-family: 'HK Grotesk';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 16px;
color: #586572;
}

#strike_price{
    background-color: #FFEEDC;
}

#strike_price_border{
    background-color: #FFEEDC;
    border-bottom: 1px solid #E4EAF0;
    border-left: 1px solid #E4EAF0;
    border-right: 1px solid #E4EAF0;
}

// #strike_price_border.dark_theme{
//     background-color: #121212;
//     color: #ffffff;
// }

#head_border{
    // height:61px;
    border-bottom: 1px solid #E4EAF0;
}

.DarkTheme #head_border{
  // height:61px;
  border-bottom: 1px solid #434651;
}

// #MAINTABLE.dark_theme{
//     background-color: #1d1d1d;
// }


.percentageBar{
    opacity: 0.4;
    position: absolute;
    z-index: 1;
    height: 15px;
    // margin-top: 2px;
    max-width: 80px;
}

.callBar{
    background:#FFAEAE;
    left:0;
    border-radius: 0 50px 50px 0 ;
    margin-left: 12px;
}
.putBar{
    background:#6FECA4;
    right:0;
    border-radius: 50px 0 0 50px ;
    margin-right: 12px;
}


#buy_sell_put{
    background: linear-gradient(90deg, #FFE8CD 35.08%, rgba(255, 232, 205, 0) 63.38%);
    position: absolute;
    width: 330px;
    height:100%;
    top: 0px;
    display: none;
    padding: 0;
    z-index: 10;
}
.DarkTheme #buy_sell_put{
  background: linear-gradient(90deg, #E4EAF0 35.08%, rgba(255, 232, 205, 0) 63.38%);
  position: absolute;
  width: 330px;
  height:100%;
  top: 0px;
  display: none;
  padding: 0;
  z-index: 10;
}
// #buy_sell_put.dark_theme{
//     background: linear-gradient(90deg, #373737 35.08%, rgba(255, 232, 205, 0) 63.38%);

// }
#buy_sell_call{
    background: linear-gradient(270deg, #FFE8CD 35.08%, rgba(255, 232, 205, 0) 63.38%);
    position: absolute;
    width: 330px;
    height:100%;
    top: 0px;
    right:0px;
    display: none;
    padding: 0;
    z-index: 10;
}
.DarkTheme #buy_sell_call{
  background: linear-gradient(270deg, #E4EAF0 35.08%, rgba(255, 232, 205, 0) 63.38%);
  position: absolute;
  width: 330px;
  height:100%;
  top: 0px;
  right:0px;
  display: none;
  padding: 0;
  z-index: 10;
}
// #buy_sell_call.dark_theme{
//     background: linear-gradient(270deg, #373737 35.08%, rgba(255, 232, 205, 0) 63.38%);

// }

#head_border_bsp{
    // height:50px;
    border-bottom: 1px solid #E4EAF0;
    position: relative;
}

.DarkTheme #head_border_bsp{
  // height:50px;
  border-bottom: 1px solid #434651;
  position: relative;
}
#head_border_bsc{
    // height:50px;
    border-bottom: 1px solid #E4EAF0;
    position: relative;
}

.DarkTheme #head_border_bsc{
  border-bottom: 1px solid #434651;
  position: relative;
}

#head_border_bsp:hover #buy_sell_put { display: block; }
#head_border_bsc:hover #buy_sell_call { display: block; }

.bsp_buttons{
    position: absolute;
    top: -2px;
    left: 10px;
}

.bsc_buttons{
    position: absolute;
    top: -2px;
    right: 10px;
}

#option_filter{
position:absolute;
top:0px;
z-index:100;
right:0px;
display:none;
border-left: 1px solid #E4EAF0;
height: 100%;
background-color: #FFFFFF;
border-radius: 8px;
}

// #option_filter.dark_theme{
//     background-color: #242424;
// }

.filter_header{
width: 285px;
height: 61px;
background: #F6F8FC;
border-radius: 0px 12px 0px 0px;
}

// .filter_header.dark_theme{
//     background: #2C2C2C;
// }

.filter_name{
    font-family: 'HK Grotesk';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
text-align: left;
color: #171D27;
}

// .filter_name.dark_theme{
//     color: #ffffff;
// }

.greeks_name{
    font-family: 'HK Grotesk';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
text-align: left;
color: #000000;
}

// .greeks_name.dark_theme{
//     color: #ffffff;
// }

.reset{
    font-family: 'HK Grotesk';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
text-align: right;
color: #064AD3;
}
// .reset.dark_theme{
//     color:#ffffff
// }


.greeks_button{
padding: 5px 12px;
// width: 112px;
height: 32px;
border-radius: 6px;
border: 1px solid #E4EAF0;
cursor: pointer;
font-weight: 600;
font-size: 14px;
line-height: 18px;
background-color: #ffffff;
color: #000000;
}

.nodata{
  font-size: 22px;
    font-weight: 600;
    margin-top: 20%;
    color: #171D27;
  }

.DarkTheme .nodata{
      color: #8392A0;
}

// .DarkTheme .greeks_button{
//   padding: 5px 12px;
//   // width: 112px;
//   height: 32px;
//   border-radius: 6px;
//   border: 1px solid #8392A0;
//   cursor: pointer;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 18px;
//   background-color: #323232;
//   color: #ffffff;
//   }

.strikesAtm{
font-family: 'HK Grotesk';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
color: #26313D;
text-align: left;
}

// .strikesAtm.dark_theme{
//     color: #ffffff;
// }

.Atm_Values{
padding: 4px 12px;
height: 32px;
background-color: #FFFFFF;
color: #000000;
border-radius: 6px;
border: 1px solid #E4EAF0;
cursor: pointer;
}

// .DarkTheme .Atm_Values{
//   padding: 4px 12px;
//   height: 32px;
//   background: #323232;
//   color:#FFFFFF;
//   border-radius: 6px; 
//   border: 1px solid #8392A0;
//   cursor: pointer;
//   }

.active_filter{
    padding: 4px 12px;
    height: 32px;
    border-radius: 6px;
    background: #064AD3;
    color: white;
    border: 1px solid #064AD3;
    cursor: pointer;
    }

// .DarkTheme .active_filter{
//       padding: 4px 12px;
//       height: 32px;
//       border-radius: 6px;
//       background: #FFFFFF;
//       color: #000000;
//       border: 1px solid #8392A0;
//       cursor: pointer;
//       }

.showStrikes{
    font-family: 'HK Grotesk';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
color: #000000;
}

// .showStrikes.dark_theme{
//     color: #ffffff;
// }

#showOI {
    opacity: 0;
    width: 0;
    height: 0;
}
.OIToggle{
    text-align: right;
}

.toggle{
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 22px;
    bottom: 0;
    background: #D0D9E2;
    transition: 0.3s;
    border-radius: 30px;
    width: 38px;
}

.DarkTheme .toggle{
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 22px;
  bottom: 0;
  background: #323232;
  transition: 0.3s;
  border-radius: 30px;
  width: 38px;
}

.toggle:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 3px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: 0.3s;
  }

  // .DarkTheme .toggle:before {
  //   position: absolute;
  //   content: "";
  //   height: 17px;
  //   width: 17px;
  //   left: 4px;
  //   bottom: 3px;
  //   background-color: #000000;
  //   border-radius: 50%;
  //   transition: 0.3s;
  // }
  
  input:checked + .toggle {
    background-color: #064AD3;
  }

  .DarkTheme input:checked + .toggle {
    background-color: #8392A0;
  }
  
  input:checked + .toggle:before {
    transform: translateX(14px);
  }

  .optionChainHeader{
    width:100px;
    text-align:center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .optionChainData{
    width:100px;
    text-align:center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .optionHeaderFormat{
    font-size:12px;
    color:#000000;
    font-weight: 700;
  }
 .line{ 
  flex: 1;
  height: 2px;
  background: #171D27;
  position: relative;
  top:8px;
  -webkit-transform:translateZ(0);
 }
 .strike_price_live{
    border-radius: 50px;
    background: #171D27;
    color: #FFFFFF;
    position: absolute;
    -webkit-transform:translateZ(0);
    top:0px;
    width: 202px;
  }


  .safari_strike_price_live{
    border-radius: 50px;
    background: #171D27;
    color: #FFFFFF;
    position: absolute;
    -webkit-transform:translateZ(0);
    top:-8px;
    width: 202px;
  }

  .safari_line{ 
    flex: 1;
    height: 2px;
    background: #171D27;
    position: relative;
    top:0px;
    -webkit-transform:translateZ(0);
  }
//   .strike_price_live.dark_theme{
//     background: #ffffff;
//     color: #171D27;
//   }

  #spot_price_live{
    position: relative;
    top: -8px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    -webkit-transform:translateZ(0);
  }
  
  .tooltip1 .tooltiptext {
    visibility: hidden;
      background-color: #171D27;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 100;
      bottom: -55px;
      left: 69%;
      margin-left: -39px;
      opacity: 0;
      transition: opacity 0.3s;
      width: 311px;
  }
  
  .tooltip1 .tooltiptext::after {
      content: "";
      position: absolute;
      top: -14%;
      left: 89%;
      margin-left: 15px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #171D27 transparent;
  }
  
  .tooltip1:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  #spot_price_live{
    scroll-margin: -200px;
  }

  .delta_active_button{
    background:#064AD3;
    color: #FFFFFF;
    border-color: #064AD3;
  }

  .gamma_active_button{
    background:#064AD3;
    color: #FFFFFF;
    border-color: #064AD3;
  }
  .theta_active_button{
    background:#064AD3;
    color: #FFFFFF;
    border-color: #064AD3;
  }
  .vega_active_button{
    background:#064AD3;
    color: #FFFFFF;
    border-color: #064AD3;
  }


  // .DarkTheme .delta_active_button{
  //   background:#FFFFFF;
  //   color: #000000;
  // }

  // .DarkTheme .gamma_active_button{
  //   background:#FFFFFF;
  //   color: #000000;
  // }
  // .DarkTheme .theta_active_button{
  //   background:#FFFFFF;
  //   color: #000000;
  // }
  // .DarkTheme .vega_active_button{
  //   background:#FFFFFF;
  //   color: #000000;
  // }

  .callChainValue{
    text-align: left;
    padding-left: 12px;
  }

  .putChainValue{
    text-align: right;
    padding-right: 12px;
  }

  .callheader{
    text-align: left;
    margin-left: 8px;
  }
  .putheader{
    text-align: right;
    margin-right: 8px;
  }


  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-container {
    // display: grid;
    // justify-content: center;
    // align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 350px;
  }

  .greenText1{
    color: #28C269;
}
.redText1{
    color: #FE4141;
}


.DarkTheme #topHead th{
  color: #8392A0;
  font-family: 'HK Grotesk';
font-style: normal;
font-weight: 900;
font-size: 15px;
line-height: 23px;
}

.DarkTheme .topSymbolName{
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #8392A0;
  display: inline;
}

.DarkTheme .exchange{
  padding: 1px 4px;
  background: #343434;;
  border-radius: 2px;
  display: inline;
  position: relative;
  font-size: 9px;
  bottom: 3px;
  left: 5px;
}

.DarkTheme .ltp{
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #8392A0;
  display: inline;
  padding: 1px; 
}

.DarkTheme .form-select{
  background-color: #171D27;
  color: #8392A0;
}

.DarkTheme .selectDate{
  font-weight: 600;
font-size: 14px;
line-height: 30px;
color: #8392A0;
}

.DarkTheme .call_put{
  font-family: 'HK Grotesk';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
color: #8392A0;
border-bottom: 1px solid #434651;
background-color: #121212;
}


.DarkTheme .chainValue{
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgb(178, 181, 190);
}


.DarkTheme .optionHeaderFormat{
  color:rgb(178, 181, 190);
}

/* .DarkTheme #MAINTABLE,tr,th{
  color: #8392A0;
  font-family: 'HK Grotesk';
font-style: normal;
font-weight: 900;
font-size: 15px;
line-height: 23px;
} */

.DarkTheme #strike_price_border{
  background-color: #171D27;
  color: #8392A0;
  border-bottom: 1px solid #434651;
  border-left: 1px solid #434651;
  border-right: 1px solid #434651;
}

.DarkTheme #MAINTABLE{
  background-color: #1d1d1d;
}

.DarkTheme #buy_sell_put{
  background: linear-gradient(90deg, #373737 35.08%, rgba(255, 232, 205, 0) 63.38%);

}

.DarkTheme #buy_sell_call{
  background: linear-gradient(270deg, #373737 35.08%, rgba(255, 232, 205, 0) 63.38%);
}

.DarkTheme #option_filter{
  background-color: #171D27;
}

.DarkTheme .filter_header{
  background: #171D27;
}

.DarkTheme .filter_name{
  color: #8392A0;
}

.DarkTheme .greeks_name{
  color: #8392A0;
}

.DarkTheme .reset{
  color:#8392A0;}

.DarkTheme .strikesAtm{
  color: #8392A0;
}

.DarkTheme .showStrikes{
  color: #8392A0;
}

.DarkTheme .strike_price_live{
  background: #8392A0;
  color: #171D27;
}

.DarkTheme .safari_strike_price_live{
  background: #8392A0;
  color: #171D27;
}

.DarkTheme .addFilter{
stroke: #8392A0;
}

.DarkTheme .info-circles{
stroke: #8392A0;
}
.DarkTheme .info-circlef{
fill: #8392A0;
}

.DarkTheme .refresh{
stroke: #8392A0;
}
/* Causing cross color white*/
.DarkTheme .closeoption{
stroke: #8392A0;
}

.DarkTheme .tooltip1 .tooltiptext{
background-color: #8392A0;
color: #171D27;
}

.DarkTheme .tooltip1 .tooltiptext::after {
border-color: transparent transparent #8392A0 transparent;

}


.DarkTheme .header{
	background-color: #171D27;
	color: #8392A0;
}
.DarkTheme #MAINTABLE thead{
background-color:#171D27;
}

.DarkTheme #MAINTABLE tbody{
  background-color:#171D27;
  }
  
.DarkTheme .optionChain{
  position: absolute;
  top: 5%;
  left: calc(8% + 56px);
  box-sizing: border-box;
  padding: 0px 0px;
  border: 1px solid #434651;
  width: 1148px;
  background: #171D27;
  box-shadow: 0px 8px 33px 8px #18274B17
;
  border-radius: 12px;
  z-index: 40;
}

.link{
  color:#4683FC
}
.DarkTheme .link{
  color: #171D27;
}

.DarkTheme #option_filter{
  border: 0px;
}
