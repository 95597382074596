.redirect_btn{
    display: flex;
    width: 220px;
    padding: 10px 31px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 90px;
    background:#064AD3;
    color: #FFFFFF;
}

.Header{
    padding: 10px 20px;
    color:#171D27;
    font-family: HK Grotesk;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.top{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid grey;
}

.center_btn{
    display: flex;
    align-items: center;
}