.custom-order-dialog_basket {
  position: absolute;
  top: 10%;
  left: calc(8% + 385px);
  box-sizing: border-box;
  padding: 0px 0px;
  border: 0px solid #143a8799;
  width: 55%;
  //height: 600px;
  background: #FFFFFF;
  box-shadow: 0px 8px 33px 8px #18274B17
;
  border-radius: 12px;
  z-index: 41;
}

.DarkTheme .custom-order-dialog_basket{
  border: 1px solid #434651;
  box-shadow: 8px 8px 4px rgba(82, 82, 82, 0.1);
}

.custom-order-dialog__header_basket{
    display: flex;
    width: 100%;
    padding: 16px 20px 16px 20px;
    flex-direction: row;
    background: #F6F8FC;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px 12px 0px 0px;
    cursor: move;
}
.DarkTheme .custom-order-dialog__header_basket{
  border-bottom: 2px solid #434651;
}

.my_box{
  position: relative;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.DarkTheme .my_box{
  border-bottom: 2px solid #434651;
  background: #1e222d;
  border-radius: 12px;
}

.bucket_name_bg{
  margin: 20px;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #F6F8FC;
  border-radius: 12px;
}

.DarkTheme .bucket_name_bg{
  border-radius: 0px;
  border-bottom: 1px solid #434651;
}

.details_1{
  font-family: 'HK Grotesk', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #171D27;
}
.w-40{
  width: 40%;
}




.trash_box{
  display: none;
}
.stock_box{
  display: block;
}

.bucket_name_bg:hover  .stock_box{
    display: none;
}
.bucket_name_bg:hover .trash_box{
  display: block;
}

.create_new_btn{
  position: absolute;
  bottom: 0;
  right: 0;
}

.mysearch{
  position: absolute;
  top: 10px;
  right: 15px;
}

.myclear {
  position: absolute;
  top: 4px;
  right: 6px;
}

.clearbtn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  background: #E4EAF0;
  border-radius: 90px;
  color: #586572;
}

.searchData{
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  top:50px;
  width: 100%;
  height: 350px;
  background: #FFFFFF;
  border: 1px solid #E4EAF0;
  box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
  border-radius: 6px;
}

.srollbox2{
  overflow-y: auto;
  height: 70%;
}

.DarkTheme .srollbox2{
  border-bottom: 2px solid #434651;
}

.dragerParent{
    height: calc(100vh - 10px);
    width: calc(100vw - 10px);
}

.disclaimer{
  font-size: 14px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  padding-left:20px;
  color: #000000;
}

.DarkTheme .disclaimer{
  color:#b2b5be
}

.highlight{
  color:#000000
}

.DarkTheme .highlight{
  color:#FFFFFF
}

@media screen and ( min-width: 1100px ) {

  .custom-order-dialog_basket{
    width: 846px;
  }


}

@media screen and ( min-height: 900px ) {

  .my_box{
    height: 600px;
  }

}


//Basket Share

table.pubBasketTable thead {
  background: #F6F8FC;
}
table.pubBasketTable {
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  vertical-align: middle;
  border-collapse: separate;
  border-spacing: 0 8px;
}

table.pubBasketTable thead th {
  font-family: 'HK Grotesk', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

table.pubBasketTable th {
  padding: 16px 10px;
  vertical-align: middle;
}

.scroll_box3{
  height: 322px;
  overflow-y: auto;
}

.analyse_btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding: 8px 40px;
  border-radius: 90px;
  color: white;
  background: #FFFFFF;
  border: 1px solid #E4EAF0;
  color: #000000;
}

.execute_btn{
  background-color: #064AD3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding: 8px 40px;
  border-radius: 90px;
  color: white;
  border: 1px solid #E4EAF0;
  color: #FFFFFF;
}

.save_btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding: 8px 40px;
  border-radius: 90px;
  border: 1px solid #E4EAF0;
  color: #000;
}

.save{
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.DarkTheme .save{
  color:#b2b5be
}

.DarkTheme .save_btn{
color: #FFF;
}
.DarkTheme .headTxt th{
  color:#b2b5be
}

.save_button{
  position: absolute;
  right: 72px;
  top: 22px;
  cursor: pointer;
}

.basketAnalyseBreakEven{
  box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E4EAF0;
    border-radius: 12px;
    height: 181px;
    width: 380px;
}

.basketOptionsLeg{
  width: 382px;
    height: 107px;
    border: 1px solid #E4EAF0;
    border-radius: 12px;
}

.scrip_drop{
  width: 130px;
  border-radius: 8px;
  color: #171D27;
  font-weight: 400;
}

.DarkTheme .scrip_drop{
  border: 1px solid #b2b5be;
  background: #171D27;
  color: #b2b5be;
}

.arrow {
  border: solid #171D27;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}
.DarkTheme .arrow{
  border: solid #b2b5be;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


.DarkTheme .basketAnalyseBreakEven {
  background: #292E39;
  border: 1px solid #434651;
  border-radius: 12px;
}

.DarkTheme .basketOptionsLeg {
  border: 1px solid #434651;
  border-radius: 12px;
}


.form-select{
  background-image: none;
}


.tooltip2 .tooltiptext {
  visibility: hidden;
    background-color: #586572;
    color: #fff;
    text-align: center;
    border-radius: 9px;
    position: absolute;
    z-index: 100;
    top: 32px;
    left: 23px;
    margin-left: -39px;
    opacity: 0;
    transition: opacity 0.3s;
    width: 475px;
    font-weight: 500;
}

.tooltip2 .tooltiptext::after {
    content: "";
    position: absolute;
    top: -13%;
    left: 1px;
    margin-left: 15px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #586572 transparent;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.DarkTheme .tooltip2 .tooltiptext{
  background-color: #8392A0;
  color: #000;
  }
  
  .DarkTheme .tooltip2 .tooltiptext::after {
  border-color: transparent transparent #8392A0 transparent;
  
  }

  .validity{
    width: 100%;
    text-align: right;
    color: #171D27;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .DarkTheme .validity{
    color: #b2b5be;
  }