@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";
@import "https://www.w3schools.com/w3css/4/w3.css";
@import "../../../node_modules/bulma-switch/dist/css/bulma-switch.min.css";


.switch[type=checkbox].is-rounded+label::before, .switch[type=checkbox].is-rounded+label:before {
    border-radius: 24px;
    background-color: #D0D9E2;
}
body {
	font-family: 'HK Grotesk';
}
.custom-order-dialog {
	visibility: hidden;
	position: absolute;
	bottom: 0;
    top: 5%;
    left: calc(8% + 56px);
	box-sizing: border-box;
	padding: 0px 0px;
	border: 1px solid #E4EAF0;
	width: 392px;
	height: 90vh;
	text-align: left;
	font-size: 18px;
	background: #FFFFFF;
	box-shadow: -4px 5px 19px 3px rgba(24, 39, 75, 0.12);
	border-radius: 12px;
	overflow-y: auto;
	z-index: 41;
}

.custom-order-dialog__header {
	display: flex;
	padding: 16px 20px 16px 20px;
	flex-direction: row;
	background: #F6F8FC;
	height: 82px;
	justify-content: space-between;
    align-items: center;
	z-index: 41;
	cursor: move;
	width: 404px;
}

#custom-order-dialog__stock-name-description{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 2px;
	
}

#custom-order-dialog__stock-name-id{
	font-family: 'HK Grotesk';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height, or 129% */

	align-items: center;

	/* Background/Grey900 */

	color: #171D27;
}

#custom-order-dialog__exchange-id{
	/* margin: auto; */
	margin-left: 8px;
	text-align: center;
	justify-content: center;
	padding: 2px 4px;
	width: 28px;
	height: 16px;

	/* Background/Grey200 */

	background: #E4EAF0;
	border-radius: 2px;

	font-family: 'HK Grotesk';
	font-style: normal;
	font-weight: 400;
	font-size: 8px;
	line-height: 12px;
	color: #171D27;
}
.custom-order-dialog__close_button{
	width: 24px;
	height: 24px;
	background-image: url('../../assets/images/close-square.svg');
	background-repeat: no-repeat;
	padding: 0;
	border: 0;
	background-color: transparent;
}
.custom-order-dialog__quote{
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	padding: 0px;
	height: 20px;
	margin-top: 4px;
}

.custom-order-dialog__quote-ltp{
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	/* identical to box height, or 125% */

	display: flex;
	align-items: center;
	text-align: right;

	/* Background/Grey900 */

	color: #171D27;


	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
}

.custom-order-dialog__quote-chng{
	margin-left: 4px;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */

	text-align: right;

	/* System/Success(Default) */

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
}
.custom-order-dialog__quote-chngp{
	margin-left: 4px;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */

	text-align: right;

	/* System/Success(Default) */

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
}
.green{
	color: #28C269;
}

.red{
	color: #FE4141
}
.custom-order-dialog__side-section {
	margin-top: 30px;
}

.custom-order-dialog__side-header {
	display: inline-block;
	margin-right: 121px;
}

.custom-order-dialog__order-type-section {
	margin-top: 10px;
	padding-left: 15px;
	padding-right: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.field:not(:last-child) {
    margin-bottom: 0rem;
}

.custom-order-dialog__order-type-header {
	display: inline-block;
	margin-right: 71px;
}

.custom-order-dialog__format-type-header{
	margin-top: 30px;
}
.custom-order-dialog__format-type-header {
	display: inline-block;
	margin-right: 71px;
}

.custom-order-dialog__validity-type-header{
	margin-top: 0px;
}
.custom-order-dialog__validity-type-header {
	display: inline-block;
	margin-right: 71px;
}
.custom-order-dialog__price-section {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}

.custom-order-dialog__price-header {
	display: inline-block;
	margin-right: 33px;
}

.custom-order-dialog__price-input {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 18px 16px;

	position: static;
	width: 164px;
	left: calc(50% - 164px/2);
	top: 30%;
	bottom: 0%;

	/* Background/White */

	background: #FFFFFF;
	/* Background/Grey200 */

	border: 1px solid #E4EAF0;
	box-sizing: border-box;
	border-radius: 6px;

	/* Inside auto layout */

	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;
	margin: 4px 0px;
}

.custom-order-dialog__qty-section {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
}

.custom-order-dialog__qty-header {
	display: inline-block;
	margin-right: 89px;
}

.custom-order-dialog__qty-input {
	display: inline-block;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 18px 16px;

	position: static;
	width: 164px;
	left: calc(50% - 164px/2);
	top: 30%;
	bottom: 0%;

	/* Background/White */

	background: #FFFFFF;
	/* Primary/Primary(Default) */

	border: 1px solid #E4EAF0;
	box-sizing: border-box;
	border-radius: 6px;

	/* Inside auto layout */

	flex: none;
	align-self: stretch;
	flex-grow: 0;
	margin: 4px 0px;
}

.custom-order-dialog__qty-input:active{
	border: 1px solid #064AD3;
}
.custom-order-dialog__tp-section {
	margin-top: 30px;
}

.custom-order-dialog__tp-header {
	display: inline-block;
	margin-right: 73px;
}

.custom-order-dialog__tp-input {
	display: inline-block;
}

.custom-order-dialog__sl-section {
	margin-top: 30px;
}

.custom-order-dialog__sl-header {
	display: inline-block;
	margin-right: 82px;
}

.custom-order-dialog__sl-input {
	display: inline-block;
}

.custom-order-dialog__buttons-block {
	text-align: center;
	margin-top: 30px;
}

.custom-order-dialog__ok-button{
	width: 100%;
	border-radius: 0;
	height: 52px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	/* identical to box height, or 125% */

	text-align: center;

	/* Background/White */

	color: #FFFFFF;
	border-end-end-radius: 12px;
	border-end-start-radius: 12px;
}

.custom-order-format{
	width: 100%
}

.order-bottombar-selected{
	border-bottom:  2px solid #171D27;
	justify-content: center;
	text-align: center;
}

.order-bottombar{
	border-bottom: 1px solid #E4EAF0;
	justify-content: center;
	text-align: center;
}

.custom-order-dialog__basic-input{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-left: 15px;
	padding-right: 15px;
}

.custom-order-dialog__sl-switch-row{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 15px;
	align-items: center;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	color: #171D27;
	width: 50%;
	font-size: 15px;
}
.custom-order-dialog__amo-switch-row{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 15px;
	padding-left: 15px;
	padding-top: 30px;
	align-items: center;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	color: #171D27;
	width: 100%;
	font-size: 15px;
}

.switch[type=checkbox]{
    background: #D0D9E2;
}

.DarkTheme .switch[type=checkbox].is-large:checked + label::before, .switch[type=checkbox].is-large:checked + label:before{
	background: #3d4453;
}

.switch[type=checkbox].is-large:checked + label::before, .switch[type=checkbox].is-large:checked + label:before{
    background: #064AD3;
}

.tabs.is-toggle li.is-active a {
    background-color: #064AD3;
    border-color: #064AD3;
    color: #fff;
    z-index: 1;
}

.custom-order-dialog__sl-value-input-container{
	display: flex;
	flex-direction: column;
	margin-left: 15px;
	margin-right: 15px;
	display: none;
}

.custom-order-dialog__sl-value-input{
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 18px 16px;

	position: static;
	width: 343px;
	left: calc(50% - 343px/2);
	top: 30%;
	bottom: 0%;

	/* Background/White */

	background: #FFFFFF;
	/* Background/Grey200 */

	border: 1px solid #E4EAF0;
	box-sizing: border-box;
	border-radius: 6px;

	/* Inside auto layout */

	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;
	margin: 4px 0px;
}
.custom-order-dialog__sl-value-input-label{
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height, or 129% */


	/* Background/Grey800 */

	color: #26313D;
}
.custom-order-dialog__validity-container {
	display: flex;
	flex-direction: column;
	margin: 15px;
	label {
	  @extend button !optional
	}
}

.custom-order-dialog__validity-types-row {
	width: 100%;
	justify-content: flex-start;
	gap: 12px;
	display: flex;
	flex-direction: row;
	label {
	  @extend button !optional
	}
}

.DarkTheme .custom-order-dialog__validity-type{
background-color: #323232;
color: #FFFFFF;
}
.DarkTheme .button.is-primary{
	background-color: #FFFFFF;
	color: #000000;
	}


.hidden {
	display: none;
}

.button.is-primary{
	background-color: #064AD3;
}
.button.is-primary:hover {
    background-color: white;
	border-color: #064AD3;
	color: #171D27;
}
.custom-order-dialog__qty-input-label{
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	/* or 129% */


	/* Background/Grey800 */

	color: #26313D;
}

.custom-order-dialog__price-input-label{
	display: flex;
	justify-content: space-between;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	/* or 129% */


	/* Background/Grey800 */

	color: #26313D;
}
.custom-order-dialog__price-tick-label{
	font-weight: 400;
	font-size: 12px;
}
.w3-third{
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */


	/* Background/Grey900 */

	color: #171D27;
}
.w3-half{
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */


	/* Background/Grey900 */

	color: #171D27;
}

.custom-order-dialog__margin-div{
	background-color: #F6F8FC	;
	height: 51px;
	padding: 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.custom-order-dialog__footer{
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.custom-order-dialog__margin-div{
	display: flex;
	flex-direction: row;
	vertical-align: middle;
}

.custom-order-dialog__required-margin-div{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.custom-order-dialog__required-margin-div-label{
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	display: flex;
	justify-content: center;
}
.custom-order-dialog__required-margin-div-value{
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	display: flex;
	justify-content: center;
}

.custom-order-dialog__available-margin-div{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.custom-order-dialog__available-margin-div-label{
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	display: flex;
	justify-content: center;
}
.custom-order-dialog__available-margin-div-value{
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	display: flex;
	justify-content: center;
}


.custom-order-dialog__price-input:disabled{
	background-color: #D0D9E2;
}

.custom-order-dialog__sl-switch-container{
	padding-top: 10px;
}
.scrollable{
	overflow-y: scroll;
}
.custom-order-dialog__sl-switch-description{

}

.custom-order-dialog__qty-input-lot-size{
	font-size: 12px;
	font-weight: bold;
}

.custom-order-dialog__price-dpr{
	font-size: 12px;
	font-weight: 600;
}

.order-details{
	display: flex;
	justify-content: space-between;
	padding-left: 15px;
	padding-right: 15px;
}

.custom-order-dialog__side-switch-row{
	display: flex;
	flex-direction: row;
	align-items: center;
}

.custom-order-dialog__actions{
	display: flex;
    flex-direction: row;
    // align-items: center;
    gap: 10px;
}
.side-switch::before{
	background-color: #28C269;
}

.side-switch.switch[type=checkbox].is-rounded + label::before, .switch[type=checkbox].is-rounded{
    border-radius: 24px;
    background-color:#FE4141;
}

.side-switch.switch[type=checkbox].is-rounded:checked + label::before, .switch[type=checkbox].is-rounded:checked{
    border-radius: 24px;
    background-color:#28C269;
}
.custom-order-dialog__charges-div{
	cursor: pointer;
}

.no_state_order_background{
	background-color:rgb(208, 217, 226);
}
.state_order_background{
	background-color:white;
}